import { FC } from 'react';

import { Button, Group, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import { ProfileType, useModifyUserMutation } from '@api/userApi';

interface EditProfileInformationFormProps {
  email?: string;
  name?: string;
  phone?: string;
  vatTaxNumber?: string;
  pec?: string | null;
  sdi?: string | null;
  type?: ProfileType;
  onClose: () => void;
}

const EditProfileInformationForm: FC<EditProfileInformationFormProps> = ({
  email,
  name,
  phone,
  vatTaxNumber,
  sdi,
  pec,
  type,
  onClose,
}) => {
  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    // email: email || '',
    // name: name || '',
    phone: phone || '',
    // vatTaxNumber: vatTaxNumber || '',
    ...(type === 'company' ? { sdi: sdi || '', pec: pec || '' } : {}),
  };

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await modifyUser(values).unwrap();

      showNotification({
        title: 'Informazioni modificate',
        message:
          'Le informazioni del tuo account sono state modificate con successo',
      });

      onClose();
    } catch (e: any) {
      if (e.status === 400) {
        form.setErrors({ general: e.data.message, ...e.data.errors });
      } else {
        form.setErrors({
          general: 'Errore inatteso. Per favore riprova più tardi.',
        });
      }
    }
  };

  // ==========================================================================
  // Api
  // ==========================================================================
  const [modifyUser, { isLoading }] = useModifyUserMutation();

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      {/* <TextInput
        label="Email"
        placeholder="email@example.com"
        required
        {...form.getInputProps('email')}
      /> */}
      {/* <TextInput
        mt="md"
        label="Nome"
        placeholder={
          type === 'company' ? 'Ragione sociale' : 'Il tuo nome e cognome'
        }
        {...form.getInputProps('name')}
      /> */}
      <TextInput
        mt="md"
        label="Telefono"
        placeholder="345 1213456"
        {...form.getInputProps('phone')}
      />
      {/* <TextInput
        mt="md"
        label={
          type === 'company' ? 'P. Iva o Codice fiscale' : 'Codice fiscale'
        }
        placeholder={type === 'company' ? 'PIVA o CF' : 'Il tuo codice fiscale'}
        {...form.getInputProps('vatTaxNumber')}
      /> */}
      {type === 'company' && (
        <>
          <TextInput
            mt="md"
            label="Pec"
            placeholder="aziendasrl@pec.it"
            {...form.getInputProps('pec')}
          />
          <TextInput
            mt="md"
            label="SDI"
            placeholder="SDI dell'azienda"
            {...form.getInputProps('sdi')}
          />
        </>
      )}
      {form.errors.general && (
        <Text color="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Group mt="xl">
        <Button variant="default" onClick={onClose} loading={isLoading}>
          Annulla
        </Button>
        <Button type="submit" loading={isLoading}>
          Conferma
        </Button>
      </Group>
    </form>
  );
};

export default EditProfileInformationForm;
