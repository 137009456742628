import { FC } from 'react';

import { Button, Group, PasswordInput, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import { useModifyUserMutation } from '@api/userApi';

interface EditUserPasswordFormProps {
  onClose: () => void;
}

const EditUserPasswordForm: FC<EditUserPasswordFormProps> = ({ onClose }) => {
  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    oldPassword: '',
    password: '',
    passwordConfirm: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Le password non corrispondono.' : null,
    },
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await modifyUser(values).unwrap();

      showNotification({
        title: 'Password modificata',
        message: 'La password del tuo account è stata modificata con successo',
      });

      onClose();
    } catch (e: any) {
      if (e.status === 400) {
        form.setErrors({ general: e.data.message, ...e.data.errors });
      } else {
        form.setErrors({
          general: 'Errore inatteso. Per favore riprova più tardi.',
        });
      }
    }
  };

  // ==========================================================================
  // Api
  // ==========================================================================
  const [modifyUser, { isLoading }] = useModifyUserMutation();

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <PasswordInput
        label="Vecchia password"
        placeholder="La tua password attuale"
        required
        {...form.getInputProps('oldPassword')}
      />
      <PasswordInput
        label="Nuova password"
        placeholder="La tua nuova passsword"
        required
        {...form.getInputProps('password')}
      />
      <PasswordInput
        label="Ripeti password"
        placeholder="La tua nuova passsword"
        required
        {...form.getInputProps('passwordConfirm')}
      />
      {form.errors.general && (
        <Text color="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Group mt="xl">
        <Button variant="default" onClick={onClose} loading={isLoading}>
          Annulla
        </Button>
        <Button type="submit" loading={isLoading}>
          Conferma
        </Button>
      </Group>
    </form>
  );
};

export default EditUserPasswordForm;
