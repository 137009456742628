import { FC, ReactNode } from 'react';

import { ActionIcon, Group, MantineNumberSize, Paper, Title } from '@mantine/core';

import useStyles from './card.style';

interface CardProps {
  title?: string;
  children?: ReactNode;
  action?: {
    title: string;
    icon: ReactNode;
    onClick: () => void;
  };
  fullWidthBreakpoint?: MantineNumberSize;
  [x: string]: any;
}

const Card: FC<CardProps> = ({
  title,
  children,
  action,
  fullWidthBreakpoint = 'md',
  ...otherProps
}) => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { classes } = useStyles();

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Paper
      p="xl"
      {...otherProps}
      className={(otherProps.className || '') + ' ' + classes.informationCard}
      sx={(theme) => ({
        [theme.fn.smallerThan(fullWidthBreakpoint)]: {
          maxWidth: '100%',
        },
      })}
    >
      {title && (
        <Group noWrap mb="lg" position="apart">
          <Title order={2}>{title}</Title>
          {action && (
            <ActionIcon title={action.title} onClick={action.onClick}>
              {action.icon}
            </ActionIcon>
          )}
        </Group>
      )}
      {children}
    </Paper>
  );
};

export default Card;
