import { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FileText } from 'tabler-icons-react';

import { Anchor } from '@mantine/core';

import env from '@config/env';

import { SortOrder } from '@domain/types';

import {
  InvoiceApiQueryParams,
  SortBy,
  useGetInvoicesCountQuery,
  useGetInvoicesQuery
} from '@api/invoicesApi';

import Layout from '@components/layout/Layout';
import PaginationRow from '@components/PaginationRow';
import SortableTable from '@components/sortableTable/SortableTable';

const Invoices: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams] = useSearchParams();

  const selectedInvoice = searchParams.get('sel');

  // ==========================================================================
  // State
  // ==========================================================================
  const [filters, setFilters] = useState<InvoiceApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 5),
  });

  // ==========================================================================
  // Api
  // ==========================================================================

  // Get initial orders count
  const {
    data: invoicesCount = { count: 0 },
    isLoading: isLoadingInvoicesCount,
  } = useGetInvoicesCountQuery();

  const { data = [], isLoading } = useGetInvoicesQuery({
    ...filters,
  });

  // Map data
  const invoices = data.map((invoice) => {
    const data = [
      invoice.id === selectedInvoice ? (
        <strong>{new Date(invoice.date).toLocaleDateString('IT-it')}</strong>
      ) : (
        new Date(invoice.date).toLocaleDateString('IT-it')
      ),
      invoice.id === selectedInvoice ? (
        <strong>{invoice.id}</strong>
      ) : (
        invoice.id
      ),
      <Anchor
        href={`${env.RESOURCES_URL}/fatture/${invoice.pdfName}`}
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <FileText />
      </Anchor>,
    ];

    return {
      key: invoice.id,
      data,
    };
  });

  // ==========================================================================
  // Render
  // ==========================================================================
  const totalPages = Math.ceil(invoicesCount.count / filters.pageLength!);

  return (
    <Layout title="Fatture" loading={isLoading || isLoadingInvoicesCount}>
      <SortableTable
        data={invoices}
        headings={{
          date: 'Data',
          id: 'Numero fattura',
          pdfLink: 'Pdf',
        }}
        sortableKeys={['id', 'date']}
        onSortingChange={(key: string, order: SortOrder) =>
          setFilters({ ...filters, sortBy: key as SortBy, sortOrder: order })
        }
        emptyText="Nessuna fattura trovata"
        loading={isLoading || isLoadingInvoicesCount}
      />
      {invoices.length > 0 && (
        <PaginationRow
          page={filters.page!}
          pageLength={filters.pageLength!}
          totalPages={totalPages}
          onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
          onPageLengthChange={(newPageLength) =>
            setFilters({ ...filters, pageLength: newPageLength, page: 1 })
          }
        />
      )}
    </Layout>
  );
};

export default Invoices;
