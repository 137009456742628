import { createStyles } from '@mantine/core';

const useStyles = createStyles((_theme) => ({
  bannerContainer: {
    position: 'fixed',
    zIndex: 9999,
    bottom: 0,
    backgroundColor: 'rgba(255,0,0,0.7)',
    color: '#ffffff',
    padding: '10px',
    pointerEvents: 'none',
  },
}));

export default useStyles;
