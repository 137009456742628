import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  notificationButton: {
    width: '100%',
    borderRadius: theme.radius.md,

    '&:hover': {
      backgroundColor: '#f8f9fa',
    },
  },

  notificationIcon: {
    width: '2.5em',
    height: '2.5em',
    borderRadius: '50%',
    backgroundColor: theme.colors.gray[2],
    display: 'grid',
    placeItems: 'center',

    img: {
      width: '50%',
      height: '50%',
    },
  },
}));

export default useStyles;
