import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Center, Loader } from '@mantine/core';
import { showNotification } from '@mantine/notifications';

import env from '@config/env';

import { getPushNotificationsToken, onMessageListener } from '@lib/firebase';

import { useRefreshMutation } from '@api/authApi';
import { useModifyUserMutation } from '@api/userApi';

import { setCredentials } from '@slices/authSlice';

import {
  Account,
  CompleteRegistration,
  Ddt,
  Invoices,
  Login,
  NotFound,
  Notifications,
  OfferDetail,
  Offers,
  OrderDetail,
  OrderDetailWebRedirect,
  Orders,
  PasswordReset,
  PasswordResetRequest,
  Register
} from '@pages';

import PrivateRoute from '@components/PrivateRoute';
import StagingBanner from '@components/stagingBanner/StagingBanner';

const App: FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [refresh] = useRefreshMutation();

  const [modifyUser] = useModifyUserMutation();

  // Initial token refresh and ask for push notification permission
  useEffect(() => {
    const f = async () => {
      try {
        const { accessToken, userId } = await refresh().unwrap();

        dispatch(setCredentials({ accessToken, user: { id: userId } }));

        const token = await getPushNotificationsToken();

        await modifyUser({ fcmToken: token }).unwrap();
      } catch (e) {
        // Nothing to do
      } finally {
        setLoading(false);
      }
    };

    f();
  }, [dispatch, refresh, modifyUser]);

  onMessageListener((payload) => {
    if (payload) {
      showNotification({
        title: payload.notification?.title,
        message: payload.notification?.body,
      });
    }
  });

  return loading ? (
    <Center style={{ height: '100vh' }}>
      <Loader />
    </Center>
  ) : (
    <>
      {env.ENV === 'staging' && <StagingBanner />}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/password-reset" element={<PasswordResetRequest />} />
          <Route path="/password-reset/:token" element={<PasswordReset />} />
          <Route
            path="/completa-registrazione/:token"
            element={<CompleteRegistration />}
          />
          <Route path="/registrati" element={<Register />} />
          <Route
            path="/ordini"
            element={<PrivateRoute element={<Orders />} />}
          />
          <Route
            path="/ordini/:id"
            element={<PrivateRoute element={<OrderDetail />} />}
          />
          <Route
            path="/ordini/web/:id"
            element={<PrivateRoute element={<OrderDetailWebRedirect />} />}
          />
          <Route
            path="/account"
            element={<PrivateRoute element={<Account />} />}
          />
          <Route
            path="/notifiche"
            element={<PrivateRoute element={<Notifications />} />}
          />
          <Route
            path="/documenti/fatture"
            element={<PrivateRoute element={<Invoices />} />}
          />
          <Route
            path="/documenti/ddt"
            element={<PrivateRoute element={<Ddt />} />}
          />
          <Route
            path="/offerte"
            element={<PrivateRoute element={<Offers />} />}
          />
          <Route
            path="/offerte/:id"
            element={<PrivateRoute element={<OfferDetail />} />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
