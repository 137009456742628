import { FC, useState } from 'react';
import { ChevronDown, ChevronUp, Selector } from 'tabler-icons-react';

import {
  Center,
  Group,
  LoadingOverlay,
  Paper,
  ScrollArea,
  Table,
  Text,
  UnstyledButton
} from '@mantine/core';

import { SortOrder } from '@domain/types';

import useStyles from './sortableTable.style';

interface ThProps {
  children: React.ReactNode;
  reversed?: boolean;
  sorted?: boolean;
  onSort?(): void;
}

const Th: FC<ThProps> = ({ children, reversed, sorted, onSort }) => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { classes } = useStyles();

  // ==========================================================================
  // Render
  // ==========================================================================
  const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : Selector;

  return (
    <th className={classes.th}>
      {onSort ? (
        <UnstyledButton onClick={onSort} className={classes.control}>
          <Group position="apart" noWrap>
            <Text weight={500} size="sm">
              {children}
            </Text>

            <Center className={classes.icon}>
              <Icon size={14} />
            </Center>
          </Group>
        </UnstyledButton>
      ) : (
        <Text weight={500} size="sm" px="md" py="xs">
          {children}
        </Text>
      )}
    </th>
  );
};

interface TableData {
  key: string;
  data: any[];
}

interface PaginationTableProps {
  data: TableData[];
  headings: any;
  sortableKeys?: string[];
  onSortingChange?: (key: string, order: SortOrder) => void;
  emptyText: string;
  loading?: boolean;
}

const SortableTable: FC<PaginationTableProps> = ({
  data,
  headings,
  sortableKeys = [],
  onSortingChange = null,
  emptyText = 'Nessun elemento presente',
  loading,
}) => {
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: string) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    if (onSortingChange) {
      onSortingChange(field, reversed ? 'desc' : 'asc');
    }
  };

  const headingsElements = Object.entries(headings).map(([key, title]) => {
    if (sortableKeys.includes(key)) {
      return (
        <Th
          key={key}
          sorted={sortBy === key}
          reversed={reverseSortDirection}
          onSort={() => setSorting(key)}
        >
          {title as string}
        </Th>
      );
    } else {
      return <Th key={key}>{title as string}</Th>;
    }
  });

  const rows = data.map((row) => (
    <tr key={row.key}>
      {Object.values(row.data).map((cell: any, i) => (
        <td key={i}>{cell}</td>
      ))}
    </tr>
  ));

  return (
    <>
      <Paper sx={{ overflow: 'hidden', position: 'relative' }}>
        <LoadingOverlay visible={loading || false} />
        <ScrollArea>
          <Table
            horizontalSpacing="md"
            verticalSpacing="xs"
            striped
            sx={{ minWidth: 700 }}
          >
            <thead>
              <tr>{headingsElements}</tr>
            </thead>
            <tbody>
              {rows.length > 0 ? (
                rows
              ) : (
                <tr>
                  <td colSpan={Object.keys(headings).length}>
                    <Text weight={500} align="center">
                      {emptyText}
                    </Text>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </ScrollArea>
      </Paper>
    </>
  );
};

export default SortableTable;
