import { FC } from 'react';

import useStyles from './stagingBanner.style';

const StagingBanner: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { classes } = useStyles();

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  return <div className={classes.bannerContainer}>Questo sito è una demo</div>;
};

export default StagingBanner;
