import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft } from 'tabler-icons-react';

import { Button, Container, LoadingOverlay, Title } from '@mantine/core';

import Footer from '@components/layout/Footer';
import Header from '@components/layout/header/Header';

interface LayoutProps {
  children?: ReactNode;
  title?: string;
  backLink?: {
    title: string;
    to: string;
  };
  loading?: boolean;
}

const Layout: FC<LayoutProps> = ({ children, title, backLink, loading }) => {
  // const headerRef = useRef(null);
  // const footerRef = useRef(null);

  // const headerFooterHeight =
  //   headerRef.current && footerRef.current
  //     ? headerRef.current['clientHeight'] + footerRef.current['clientHeight']
  //     : 0;

  // ==========================================================================
  // Render
  // ==========================================================================
  const headerFooterHeight = 103;

  return (
    <>
      <Header />
      <LoadingOverlay visible={loading || false} />
      <Container
        py={50}
        size={1450}
        style={{
          position: 'relative',
          minHeight: `calc(100vh - ${headerFooterHeight}px`,
        }}
      >
        {backLink && (
          <Button
            component={Link}
            to={backLink.to}
            variant="light"
            size="xs"
            mb="lg"
            leftIcon={<ChevronLeft />}
          >
            {backLink.title}
          </Button>
        )}
        {title && (
          <Title order={1} mb="lg">
            {title}
          </Title>
        )}
        {children}
      </Container>
      <Footer />
    </>
  );
};

export default Layout;
