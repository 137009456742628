import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Group, Pagination, Select } from '@mantine/core';

interface PaginationRowProps {
  page: number;
  pageLength: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
  onPageLengthChange: (newPageLength: number) => void;
  pageOptions?: number[];
}

const PaginationRow: FC<PaginationRowProps> = ({
  page,
  pageLength,
  totalPages,
  onPageChange,
  onPageLengthChange,
  pageOptions = [5, 10, 20, 50],
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handlePageChange = (newPage: number) => {
    setSearchParams({ ...searchParams, page: newPage.toString() });
    onPageChange(newPage);
  };

  const handlePageLengthChange = (newPageLength: number) => {
    setSearchParams({ ...searchParams, pageLength: newPageLength.toString() });
    onPageLengthChange(newPageLength);
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Group position="apart" mt="xl">
      <Pagination page={page} onChange={handlePageChange} total={totalPages} />
      <Select
        aria-label="Elementi per pagina"
        sx={{ width: '4.2em' }}
        data={pageOptions.map((opt) => opt.toString())}
        value={pageLength.toString()}
        onChange={(value) => handlePageLengthChange(+value!)}
      />
    </Group>
  );
};

export default PaginationRow;
