import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './';

export type ProfileType = 'private' | 'company';

export interface UserRegisterParams {
  email: string;
  password: string;
  name: string;
  vatTaxNumber: string;
  phone: string;
  pec?: string;
  sdi?: string;
  type: ProfileType;
  address: string;
  city: string;
  zip: string;
  province: string;
  recaptchaToken?: string;
}

export interface UserRegisterResponse {
  id: number;
}

export interface UserWithProfile {
  id: number;
  email: string;
  createdAt: string;
  updatedAt: string;
  profile: {
    name: string;
    vatTaxNumber: string;
    phone: string;
    pec: string | null;
    sdi: string | null;
    type: ProfileType;
    pushNotificationsEnabled: boolean;
    emailNotificationsEnabled: boolean;
    billingAddress: {
      id: number;
      address: string;
      city: string;
      zip: string;
      province: string;
      nation: string;
    };
  };
}

export interface UserModifyParams {
  name?: string;
  oldPassword?: string;
  password?: string;
  phone?: string;
  vatTaxNumber?: string;
  pec?: string | null;
  sdi?: string | null;
  address?: string;
  city?: string;
  zip?: string;
  province?: string;
  fcmToken?: string;
  pushNotificationsEnabled?: boolean;
  emailNotificationsEnabled?: boolean;
}

export interface UserModifyResponse {
  name: string;
  phone: string;
  pec: string | null;
  sdi: string | null;
  billingAddress: {
    id: number;
    address: string;
    city: string;
    zip: string;
    province: string;
    nation: string;
  };
  user: {
    id: number;
    email: string;
  };
}

interface CheckTokenResponse {
  message: string;
}

interface CompleteRegistrationRequest {
  token: string;
  body: { password: string; recaptchaToken?: string };
}

interface CompleteRegistrationResponse {
  message: string;
}

const api = createApi({
  baseQuery: baseQuery,
  reducerPath: 'api/user',
  tagTypes: ['User'],
  endpoints: (builder) => ({
    registerUser: builder.mutation<UserRegisterResponse, UserRegisterParams>({
      query: (body) => ({
        url: '/users',
        method: 'POST',
        body,
      }),
    }),
    getUser: builder.query<UserWithProfile, void>({
      query: () => ({
        url: '/users',
      }),
      providesTags: ['User'],
    }),
    modifyUser: builder.mutation<UserModifyResponse, UserModifyParams>({
      query: (body) => ({
        url: '/users',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    checkRegistrationToken: builder.query<CheckTokenResponse, string>({
      query: (token) => ({
        url: `/users/complete-registration/${token}`,
      }),
    }),
    completeRegistration: builder.mutation<
      CompleteRegistrationResponse,
      CompleteRegistrationRequest
    >({
      query: ({ token, body }) => ({
        url: `/users/complete-registration/${token}`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useGetUserQuery,
  useModifyUserMutation,
  useCheckRegistrationTokenQuery,
  useCompleteRegistrationMutation,
} = api;
export default api;
