export const PRODUCT_STATUS_COLORS = {
  inserted: 'grape',
  checkingAvailability: 'indigo',
  waitingConfirmation: 'orange',
  waitingPayment: 'orange',
  confirmed: 'blue',
  production: 'yellow',
  waitingGraphicsConfirmation: 'orange',
  graphicsConfirmed: 'blue',
  readyToShip: 'teal',
  closed: 'green',
  cancelled: 'gray',
};

export const PRODUCT_STATUS_STRINGS = {
  inserted: 'In elaborazione',
  checkingAvailability: 'Controllo disponibilità',
  waitingConfirmation: 'In attesa di conferma',
  waitingPayment: 'In attesa di pagamento',
  confirmed: 'Confermato',
  production: 'In produzione',
  waitingGraphicsConfirmation: 'In attesa di conferma grafica',
  graphicsConfirmed: 'Grafica confermata',
  readyToShip: 'Pronto per la spedizione',
  closed: 'Spedito',
  cancelled: 'Annullato',
};
