import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  inputGroup: {
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },
  inputField: {
    maxWidth: 'initial',
    width: '100%',
    minWidth: '15em',
  },
}));

export default useStyles;
