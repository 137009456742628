import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Button, Center, Stack, Title } from '@mantine/core';

import useAuth from '@hooks/useAuth';

import Layout from '@components/layout/Layout';

const NotFound: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { user } = useAuth();

  // ==========================================================================
  // Render
  // ==========================================================================
  return user ? (
    <Layout>
      <Center>
        <Stack align="center">
          <Title>La pagina che cerchi non esiste</Title>
          <Button to="/" component={Link}>
            Torna alla pagina iniziale
          </Button>
        </Stack>
      </Center>
    </Layout>
  ) : (
    <Center mt="xl">
      <Stack align="center">
        <Title>La pagina che cerchi non esiste</Title>
        <Button to="/" component={Link}>
          Torna alla pagina iniziale
        </Button>
      </Stack>
    </Center>
  );
};

export default NotFound;
