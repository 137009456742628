import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useGetWebOrderIdQuery } from '@api/ordersApi';

import Layout from '@components/layout/Layout';

import NotFound from './NotFound';

const OrderDetailWebRedirect: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { id } = useParams();

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data, isLoading, isError } = useGetWebOrderIdQuery(+id!);

  // ==========================================================================
  // Render
  // ==========================================================================
  return isLoading ? (
    <Layout loading={isLoading} />
  ) : data && !isError ? (
    <Navigate to={`/ordini/${data.id}`} replace={true} />
  ) : (
    <NotFound />
  );
};

export default OrderDetailWebRedirect;
