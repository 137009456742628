import { FC } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';

import { Anchor, Button, LoadingOverlay, PasswordInput, Text, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import env from '@config/env';

import { useCheckTokenQuery, usePasswordResetMutation } from '@api/passwordResetApi';

import useAuth from '@hooks/useAuth';

import FormsPaper from '@components/FormsPaper';
import GuestLayout from '@components/layout/guestLayout/GuestLayout';

const PasswordReset: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { token } = useParams();

  const { user } = useAuth();

  const navigate = useNavigate();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    password: '',
    passwordConfirm: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Le password non corrispondono.' : null,
    },
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      if (env.ENV === 'production') {
        // @ts-ignore
        window['grecaptcha'].ready(function () {
          // @ts-ignore
          window['grecaptcha']
            .execute(env.RECAPTCHA_PUBLIC_KEY, { action: 'submit' })
            .then(async (recaptchaToken: string) => {
              await passwordReset({
                token: token!,
                body: { ...values, recaptchaToken },
              }).unwrap();
            });
        });
      } else {
        await passwordReset({ token: token!, body: values }).unwrap();
      }

      showNotification({
        title: 'Password modificata correttamente!',
        message: 'Ora puoi accedere utilizzando la tua nuova password.',
      });

      navigate('/');
    } catch (e: any) {
      if (e.status === 400) {
        form.setErrors({ general: e.data.message, ...e.data.errors });
      } else if (e.status === 404) {
        showNotification({
          title: 'Url non valido!',
          message: 'Url per il ripristino della password non valido o scaduto.',
          color: 'red',
        });

        navigate('/');
      } else {
        form.setErrors({
          general: 'Errore inatteso. Per favore riprova più tardi.',
        });
      }
    }
  };

  // ==========================================================================
  // Api
  // ==========================================================================
  const { isError, isLoading: isLoadingCheckToken } = useCheckTokenQuery(
    token!
  );

  const [passwordReset, { isLoading }] = usePasswordResetMutation();

  // ==========================================================================
  // Render
  // ==========================================================================
  return user ? (
    <Navigate to="/ordini" replace={true} />
  ) : (
    <>
      <LoadingOverlay visible={isLoadingCheckToken} />
      <GuestLayout
        titleComponent={
          <>
            Sai già la tua password?{' '}
            <Anchor to="/" component={Link} size="sm">
              Accedi
            </Anchor>
          </>
        }
      >
        <FormsPaper mt={30}>
          {isError ? (
            <>
              <Text size="sm">
                Link di ripristino password non valido o scaduto.
              </Text>
              <Anchor to="/password-reset" component={Link} size="sm">
                Richiedine uno nuovo
              </Anchor>
            </>
          ) : (
            <form
              onSubmit={form.onSubmit((values) => {
                onSubmit(values);
              })}
            >
              <Title order={2} align="center" mb="lg">
                Recupera password
              </Title>
              <Text size="sm" mb="lg">
                Inserisci una nuova password.
              </Text>
              <PasswordInput
                label="Nuova password"
                placeholder="La tua nuova password"
                required
                {...form.getInputProps('password')}
              />
              <PasswordInput
                mt="md"
                label="Ripeti password"
                placeholder="La tua nuova password"
                required
                {...form.getInputProps('passwordConfirm')}
              />
              {form.errors.general && (
                <Text color="red" size="sm" mt="xl">
                  {form.errors.general}
                </Text>
              )}
              <Button type="submit" fullWidth mt="xl" loading={isLoading}>
                Conferma
              </Button>
            </form>
          )}
        </FormsPaper>
      </GuestLayout>
    </>
  );
};

export default PasswordReset;
