import { FC } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import { Anchor, Button, Text, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import env from '@config/env';

import { usePasswordResetRequestMutation } from '@api/passwordResetApi';

import useAuth from '@hooks/useAuth';

import FormsPaper from '@components/FormsPaper';
import GuestLayout from '@components/layout/guestLayout/GuestLayout';



const PasswordResetRequest: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { user } = useAuth();

  const navigate = useNavigate();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: '',
  };

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      if (env.ENV === 'production') {
        // @ts-ignore
        window['grecaptcha'].ready(function () {
          // @ts-ignore
          window['grecaptcha']
            .execute(env.RECAPTCHA_PUBLIC_KEY, { action: 'submit' })
            .then(async (recaptchaToken: string) => {
              await passwordResetRequest({
                ...values,
                recaptchaToken,
              }).unwrap();
            });
        });
      } else {
        await passwordResetRequest(values).unwrap();
      }

      showNotification({
        title: 'Richiesta ripristino password confermata!',
        message:
          'Se la email che hai inserito è corretta riceverai una email con le istruzioni per ripristinare la tua password.',
      });

      navigate('/');
    } catch (e: any) {
      if (e.status === 400) {
        form.setErrors({ general: e.data.message, ...e.data.errors });
      } else {
        form.setErrors({
          general: 'Errore inatteso. Per favore riprova più tardi.',
        });
      }
    }
  };

  // ==========================================================================
  // Api
  // ==========================================================================
  const [passwordResetRequest, { isLoading }] =
    usePasswordResetRequestMutation();

  // ==========================================================================
  // Render
  // ==========================================================================
  return user ? (
    <Navigate to="/ordini" replace={true} />
  ) : (
    <GuestLayout
      titleComponent={
        <>
          Sai già la tua password?{' '}
          <Anchor to="/" component={Link} size="sm">
            Accedi
          </Anchor>
        </>
      }
    >
      <FormsPaper mt={30}>
        <form
          onSubmit={form.onSubmit((values) => {
            onSubmit(values);
          })}
        >
          <Title order={2} align="center" mb="lg">
            Recupera password
          </Title>
          <Text size="sm" mb="lg">
            Inserisci l'email con la quale ti sei registrato e riceverai un link
            per ripristinare la tua password.
          </Text>
          <TextInput
            label="Email"
            placeholder="email@example.com"
            required
            {...form.getInputProps('email')}
          />
          {form.errors.general && (
            <Text color="red" size="sm" mt="xl">
              {form.errors.general}
            </Text>
          )}
          <Button type="submit" fullWidth mt="xl" loading={isLoading}>
            Recupera password
          </Button>
        </form>
      </FormsPaper>
    </GuestLayout>
  );
};

export default PasswordResetRequest;
