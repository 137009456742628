import { FC } from 'react';

import { Button, Group, Select, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import { useGetCittaQuery, useGetProvinceQuery } from '@api/locationsApi';
import { useModifyUserMutation } from '@api/userApi';

interface EditAddressFormProps {
  address?: string;
  city?: string;
  zip?: string;
  province?: string;
  onClose: () => void;
}

const EditAddressForm: FC<EditAddressFormProps> = ({
  address: initialAddress,
  city: initialCity,
  zip: initialZip,
  province: initialProvince,
  onClose,
}) => {
  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    address: initialAddress || '',
    city: initialCity || '',
    zip: initialZip || '',
    province: initialProvince || '',
  };

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await modifyUser(values).unwrap();

      showNotification({
        title: 'Indirizzo modificato',
        message: "L'indirizzo di fatturazione è stato modificato con successo",
      });

      onClose();
    } catch (e: any) {
      if (e.status === 400) {
        form.setErrors({ general: e.data.message, ...e.data.errors });
      } else {
        form.setErrors({
          general: 'Errore inatteso. Per favore riprova più tardi.',
        });
      }
    }
  };

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: province, isLoading: isLoadingProvince } =
    useGetProvinceQuery();

  const { data: citta, isLoading: isLoadingCitta } = useGetCittaQuery(
    form.values.province,
    { skip: form.values.province === '' }
  );

  const [modifyUser, { isLoading }] = useModifyUserMutation();

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Select
        label="Provincia"
        placeholder="Seleziona provincia"
        searchable
        nothingFound="Nessun risultato"
        required
        disabled={isLoadingProvince}
        data={
          province
            ? province.map((provincia) => ({
                value: provincia.sigla,
                label: provincia.nome,
              }))
            : []
        }
        {...form.getInputProps('province')}
        onChange={(e) => {
          form.setFieldValue('city', '');
          form.setFieldValue('zip', '');
          form.getInputProps('province').onChange(e);
        }}
      />
      <Select
        mt="md"
        label="Città"
        placeholder="Seleziona città"
        searchable
        nothingFound="Nessun risultato"
        required
        disabled={form.values.province === '' || isLoadingCitta}
        data={
          citta
            ? citta.map((citta) => ({
                value: citta.nome,
                label: citta.nome,
              }))
            : []
        }
        {...form.getInputProps('city')}
        onChange={(e) => {
          form.setFieldValue('zip', '');
          form.getInputProps('city').onChange(e);
        }}
      />
      <Select
        mt="md"
        label="CAP"
        placeholder="Seleziona CAP"
        searchable
        nothingFound="Nessun risultato"
        required
        disabled={form.values.city === '' || isLoadingCitta}
        data={
          citta && citta.length > 0 && form.values.city
            ? citta.filter((citta) => citta.nome === form.values.city)[0]
                ?.cap || []
            : []
        }
        {...form.getInputProps('zip')}
      />
      <TextInput
        mt="md"
        label="Indirizzo"
        placeholder="Via Principale, 20"
        required
        {...form.getInputProps('address')}
      />
      {form.errors.general && (
        <Text color="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}
      <Group mt="xl">
        <Button variant="default" onClick={onClose} loading={isLoading}>
          Annulla
        </Button>
        <Button type="submit" loading={isLoading}>
          Conferma
        </Button>
      </Group>
    </form>
  );
};

export default EditAddressForm;
