import { FC, LegacyRef } from 'react';
import { Link } from 'react-router-dom';

import {
  Anchor,
  Badge,
  Burger,
  Button,
  Container,
  Divider,
  Drawer,
  Group,
  MediaQuery,
  Paper,
  Text
} from '@mantine/core';
import { useToggle } from '@mantine/hooks';

import { useGetNotificationsQuery } from '@api/notificationsApi';
import { useGetUserQuery } from '@api/userApi';

import IconBadge from '@components/iconBadge/IconBadge';
import LoadingText from '@components/LoadingText';

import documentsIcon from '@images/icons/documents-icon.svg';
import notificationIcon from '@images/icons/notification-icon.svg';
import offersIcon from '@images/icons/offers-icon.svg';
import ordersIcon from '@images/icons/orders-icon.svg';
import settingsIcon from '@images/icons/settings-icon.svg';
import logo from '@images/logo.svg';

import useStyles from './header.style';

interface HeaderProps {
  innerRef?: LegacyRef<HTMLElement>;
}

const Header: FC<HeaderProps> = ({ innerRef }) => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { classes, cx } = useStyles();

  // ==========================================================================
  // State
  // ==========================================================================

  const [opened, toggleOpened] = useToggle([false, true]);
  // ==========================================================================
  // Api
  // ==========================================================================
  const { data, isLoading } = useGetUserQuery();

  const { data: notifications = [] } = useGetNotificationsQuery({
    filter: 'notRead',
  });

  // ==========================================================================
  // Render
  // ==========================================================================

  const ordersNotificationsCount = notifications.filter(
    (n) => n.category === 'newOrder'
  ).length;

  const invoicesNotificationsCount = notifications.filter(
    (n) => n.category === 'newDocumentInvoice'
  ).length;

  const ddtsNotificationsCount = notifications.filter(
    (n) => n.category === 'newDocumentDdt'
  ).length;

  const offersNotificationsCount = notifications.filter(
    (n) => n.category === 'newDocumentOffer'
  ).length;

  return (
    <header className={classes.header} ref={innerRef}>
      <Paper radius={0} shadow="sm">
        <Container className={classes.inner} fluid>
          <Anchor sx={{ maxWidth: '200px' }} to="/ordini" component={Link}>
            <img src={logo} alt="Logo Tuoteam" style={{ width: '100%' }} />
          </Anchor>
          <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
            <Group noWrap spacing={0}>
              <Button
                to="/ordini"
                component={Link}
                leftIcon={
                  <img
                    src={ordersIcon}
                    alt="Ordini"
                    style={{ height: '1.1rem' }}
                  />
                }
                variant="subtle"
                size="md"
                className={classes.link}
              >
                Ordini
                {ordersNotificationsCount > 0 && (
                  <Badge className={classes.badge} variant="filled" size="sm">
                    {ordersNotificationsCount}
                  </Badge>
                )}
              </Button>
              <Divider size="md" sx={{ width: '20px' }} />
              <Button
                to="/documenti/fatture"
                component={Link}
                leftIcon={
                  <img
                    src={documentsIcon}
                    alt="Fatture"
                    style={{ height: '1.1rem' }}
                  />
                }
                variant="subtle"
                size="md"
                className={classes.link}
              >
                Fatture
                {invoicesNotificationsCount > 0 && (
                  <Badge className={classes.badge} variant="filled" size="sm">
                    {invoicesNotificationsCount}
                  </Badge>
                )}
              </Button>
              <Divider size="md" sx={{ width: '20px' }} />
              <Button
                to="/documenti/ddt"
                component={Link}
                leftIcon={
                  <img
                    src={documentsIcon}
                    alt="DDT"
                    style={{ height: '1.1rem' }}
                  />
                }
                variant="subtle"
                size="md"
                className={classes.link}
              >
                DDT
                {ddtsNotificationsCount > 0 && (
                  <Badge className={classes.badge} variant="filled" size="sm">
                    {ddtsNotificationsCount}
                  </Badge>
                )}
              </Button>
              <Divider size="md" sx={{ width: '20px' }} />
              <Button
                to="/offerte"
                component={Link}
                leftIcon={
                  <img
                    src={offersIcon}
                    alt="Offerte"
                    style={{ height: '1.1rem' }}
                  />
                }
                variant="subtle"
                size="md"
                className={classes.link}
              >
                Offerte
                {offersNotificationsCount > 0 && (
                  <Badge className={classes.badge} variant="filled" size="sm">
                    {offersNotificationsCount}
                  </Badge>
                )}
              </Button>
            </Group>
          </MediaQuery>
          <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
            <Group noWrap spacing={0}>
              <IconBadge
                badgeText={
                  notifications.length !== 0
                    ? notifications.length.toString()
                    : null
                }
                to="/notifiche"
              />

              <Button
                to="/account"
                component={Link}
                leftIcon={
                  <img
                    src={settingsIcon}
                    alt="Impostazioni"
                    style={{ height: '1.7rem' }}
                  />
                }
                variant="subtle"
                size="md"
                className={cx(classes.link, classes.accountLink)}
                styles={{
                  label: {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  },
                }}
              >
                <Text size="xs" style={{ lineHeight: 1 }}>
                  Utente:
                </Text>
                <LoadingText
                  loading={isLoading}
                  className={classes.userName}
                  skeletonMarginBottom={0}
                  skeletonMarginTop={5}
                >
                  {data?.profile.name}
                </LoadingText>
              </Button>
            </Group>
          </MediaQuery>
          <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
            <Drawer
              opened={opened}
              onClose={() => toggleOpened()}
              styles={{
                title: {
                  maxWidth: '200px',
                },

                closeButton: {
                  height: '2em',
                  minHeight: '2em',
                  width: '2em',
                  minWidth: '2em',

                  svg: {
                    width: '100%',
                    height: '100%',
                  },
                },

                header: {
                  marginBottom: '2em',
                },
              }}
              title={
                <Anchor sx={{ maxWidth: '50px' }} to="/ordini" component={Link}>
                  <img
                    src={logo}
                    alt="Logo Tuoteam"
                    style={{ width: '100%' }}
                  />
                </Anchor>
              }
              padding="md"
              position="right"
            >
              <Button
                to="/ordini"
                component={Link}
                fullWidth
                leftIcon={
                  <img
                    src={ordersIcon}
                    alt="Ordini"
                    style={{ height: '1.1rem' }}
                  />
                }
                variant="subtle"
                size="md"
                className={classes.link}
              >
                Ordini
              </Button>
              <Divider my="xs" />
              <Button
                to="/documenti/fatture"
                component={Link}
                fullWidth
                leftIcon={
                  <img
                    src={documentsIcon}
                    alt="Fatture"
                    style={{ height: '1.1rem' }}
                  />
                }
                variant="subtle"
                size="md"
                className={classes.link}
              >
                Fatture
              </Button>
              <Divider my="xs" />
              <Button
                to="/documenti/ddt"
                component={Link}
                fullWidth
                leftIcon={
                  <img
                    src={documentsIcon}
                    alt="DDT"
                    style={{ height: '1.1rem' }}
                  />
                }
                variant="subtle"
                size="md"
                className={classes.link}
              >
                DDT
              </Button>
              <Divider my="xs" />
              <Button
                to="/offerte"
                component={Link}
                fullWidth
                leftIcon={
                  <img
                    src={offersIcon}
                    alt="Offerte"
                    style={{ height: '1.1rem' }}
                  />
                }
                variant="subtle"
                size="md"
                className={classes.link}
              >
                Offerte
              </Button>
              <Divider my="xs" />
              <Button
                to="/notifiche"
                component={Link}
                fullWidth
                leftIcon={
                  <img
                    src={notificationIcon}
                    alt="Notifiche"
                    style={{ height: '1.1rem' }}
                  />
                }
                variant="subtle"
                size="md"
                className={classes.link}
              >
                Notifiche
              </Button>
              <Divider my="xs" />
              <Button
                to="/account"
                component={Link}
                fullWidth
                leftIcon={
                  <img
                    src={settingsIcon}
                    alt="Account"
                    style={{ height: '1.1rem' }}
                  />
                }
                variant="subtle"
                size="md"
                className={classes.link}
              >
                Impostazioni account
              </Button>
            </Drawer>
          </MediaQuery>
          <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
            <Burger opened={opened} onClick={() => toggleOpened()} />
          </MediaQuery>
        </Container>
      </Paper>
    </header>
  );
};

export default Header;
