import '@styles/fonts.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { Global, MantineProvider, MantineTheme } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';

import store from '@app/store';

import env from '@config/env';

import theme from '@styles/theme';

import App from './App';

const container = document.getElementById('root');

const root = createRoot(container!);

if (env.ENV === 'production') {
  // Add google recaptcha script
  const script = document.createElement('script');
  script.src = `https://www.google.com/recaptcha/api.js?render=${env.RECAPTCHA_PUBLIC_KEY}`;
  script.defer = true;
  document.body.appendChild(script);
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider withNormalizeCSS theme={theme}>
        <Global
          styles={(theme: MantineTheme) => ({
            '*, *::before, *::after': {
              boxSizing: 'border-box',
            },
            body: {
              ...theme.fn.fontStyles(),
              backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[7] : '#f6f9fc',
              color:
                theme.colorScheme === 'dark'
                  ? theme.colors.dark[0]
                  : theme.black,
              lineHeight: theme.lineHeight,
              fontSize: theme.fontSizes.md,
            },
            '.grecaptcha-badge': {
              display: 'none !important',
            },
            '.mantine-Pagination-item[data-active]': {
              pointerEvents: 'none',
            },
          })}
        />
        <NotificationsProvider autoClose={4000}>
          <App />
        </NotificationsProvider>
      </MantineProvider>
    </Provider>
  </React.StrictMode>
);
