import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './';

interface ProfileSettings {
  key: string;
  value: string;
}

interface ProfileSettingsModifyParams {
  key: string;
  value: string;
}

interface ProfileSettingsModifyResponse {
  message: string;
}

const api = createApi({
  baseQuery: baseQuery,
  reducerPath: 'api/profileSettings',
  tagTypes: ['ProfileSettings'],
  endpoints: (builder) => ({
    getProfileSettings: builder.query<ProfileSettings[], void>({
      query: () => ({
        url: '/profile-settings',
      }),
      providesTags: ['ProfileSettings'],
    }),
    modifyProfileSettings: builder.mutation<
      ProfileSettingsModifyResponse,
      ProfileSettingsModifyParams[]
    >({
      query: (body) => ({
        url: '/profile-settings',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['ProfileSettings'],
    }),
  }),
});

export const { useGetProfileSettingsQuery, useModifyProfileSettingsMutation } =
  api;
export default api;
