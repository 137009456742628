import { createStyles } from '@mantine/core';

const useStyles = createStyles({
  logo: {
    display: 'block',
    width: '80%',
    maxWidth: '300px',
    margin: '0 auto',
  },
});

export default useStyles;
