import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './';

export interface Seller {
  id: number;
  name: string;
  email: string;
  phone: string | null;
}

const api = createApi({
  baseQuery: baseQuery,
  reducerPath: 'api/sellers',
  endpoints: (builder) => ({
    getSeller: builder.query<Seller | null, void>({
      query: () => ({
        url: '/sellers',
      }),
    }),
  }),
});

export const { useGetSellerQuery } = api;
export default api;
