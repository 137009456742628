import { createStyles } from '@mantine/core';

const useStyles = createStyles({
  modalContainer: {
    height: '100%',
    width: '100%',
  },
  slideContainer: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '5fr 1fr',
  },
  slideImage: {
    display: 'block',
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    overflow: 'auto',
  },
});

export default useStyles;
