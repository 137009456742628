import { FC, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { Button } from '@mantine/core';

import { SortOrder } from '@domain/types';

import { SortBy } from '@api/invoicesApi';
import { OffersApiQueryParams, useGetOffersCountQuery, useGetOffersQuery } from '@api/offersApi';

import Layout from '@components/layout/Layout';
import PaginationRow from '@components/PaginationRow';
import SortableTable from '@components/sortableTable/SortableTable';

const Offers: FC = () => {
  const [searchParams] = useSearchParams();

  // ==========================================================================
  // State
  // ==========================================================================
  const [filters, setFilters] = useState<OffersApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 5),
  });

  // ==========================================================================
  // Api
  // ==========================================================================

  // Get initial orders count
  const { data: offersCount = { count: 0 }, isLoading: isLoadingOffersCount } =
    useGetOffersCountQuery();

  const { data = [], isLoading } = useGetOffersQuery({
    ...filters,
  });

  // Map data
  const offers = data.map((offer) => {
    const data = [
      new Date(offer.date).toLocaleDateString('IT-it'),
      offer.id,
      <Button component={Link} to={`/offerte/${offer.id}`}>
        Dettagli
      </Button>,
    ];

    return {
      key: offer.id,
      data,
    };
  });

  // ==========================================================================
  // Render
  // ==========================================================================
  const totalPages = Math.ceil(offersCount.count / filters.pageLength!);

  return (
    <Layout title="Offerte" loading={isLoading || isLoadingOffersCount}>
      <SortableTable
        data={offers}
        headings={{
          date: 'Data',
          id: 'Numero offerta',
          offerLink: '',
        }}
        sortableKeys={['id', 'date']}
        onSortingChange={(key: string, order: SortOrder) =>
          setFilters({ ...filters, sortBy: key as SortBy, sortOrder: order })
        }
        emptyText="Nessun offerta trovata"
        loading={isLoading || isLoadingOffersCount}
      />
      {offers.length > 0 && (
        <PaginationRow
          page={filters.page!}
          pageLength={filters.pageLength!}
          totalPages={totalPages}
          onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
          onPageLengthChange={(newPageLength) =>
            setFilters({ ...filters, pageLength: newPageLength, page: 1 })
          }
        />
      )}
    </Layout>
  );
};

export default Offers;
