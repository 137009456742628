import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Pencil } from 'tabler-icons-react';

import { Anchor, Button, Divider, Group, Switch, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';

import { useLogoutMutation } from '@api/authApi';
import {
  useGetProfileSettingsQuery,
  useModifyProfileSettingsMutation
} from '@api/profileSettingsApi';
import { useGetSellerQuery } from '@api/sellersApi';
import { useGetUserQuery, useModifyUserMutation } from '@api/userApi';

import { logout } from '@slices/authSlice';

import Card from '@components/card/Card';
import EditAddressForm from '@components/EditAddressForm';
import EditProfileInformationForm from '@components/EditProfileInformationForm';
import EditUserPasswordForm from '@components/EditUserPasswordForm';
import Layout from '@components/layout/Layout';
import LoadingText from '@components/LoadingText';

const Account: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // ==========================================================================
  // State
  // ==========================================================================
  const [isEditingProfileInfo, setIsEditingProfileInfo] =
    useState<boolean>(false);
  const [isEditingPassword, setIsEditingPassword] = useState<boolean>(false);

  const [isEditingAddress, setIsEditingAddress] = useState<boolean>(false);

  const [pushNotifEnabled, setPushNotifEnabled] = useState<boolean>(false);
  const [emailNotifEnabled, setEmailNotifEnabled] = useState<boolean>(false);

  const [pushNotifNewOrderStatusEnabled, setPushNotifNewOrderStatusEnabled] =
    useState<boolean>(false);
  const [pushNotifNewDocumentEnabled, setPushNotifNewDocumentEnabled] =
    useState<boolean>(false);
  const [pushNotifNewOrderEnabled, setPushNotifNewOrderEnabled] =
    useState<boolean>(false);
  const [
    pushNotifNewGraphicsDraftEnabled,
    setPushNotifNewGraphicsDraftEnabled,
  ] = useState<boolean>(false);

  const [emailNotifNewOrderStatusEnabled, setEmailNotifNewOrderStatusEnabled] =
    useState<boolean>(false);
  const [emailNotifNewDocumentEnabled, setEmailNotifNewDocumentEnabled] =
    useState<boolean>(false);
  const [emailNotifNewOrderEnabled, setEmailNotifNewOrderEnabled] =
    useState<boolean>(false);
  const [
    emailNotifNewGraphicsDraftEnabled,
    setEmailNotifNewGraphicsDraftEnabled,
  ] = useState<boolean>(false);

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: userData, isLoading: isLoadingUser } = useGetUserQuery();
  const { data: sellerData, isLoading: isLoadingSeller } = useGetSellerQuery();

  const [logoutFun, { isLoading }] = useLogoutMutation();

  const { data: profileSettingsData, isLoading: isLoadingProfileSettings } =
    useGetProfileSettingsQuery();

  const [modifyUser, { isLoading: isModifyUserLoading }] =
    useModifyUserMutation();

  const [modifyProfileSettings, { isLoading: isModifyProfileSettingsLoading }] =
    useModifyProfileSettingsMutation();

  useEffect(() => {
    const checkPushPermissions = async () => {
      // Check push notifications permissions
      if (Notification.permission !== 'granted') {
        // Disable push
        try {
          await modifyUser({ pushNotificationsEnabled: false }).unwrap();
        } catch (e) {
          console.error(e);
        }
      }
    };

    checkPushPermissions();
  }, [modifyUser]);

  useEffect(() => {
    if (userData) {
      setPushNotifEnabled(userData.profile.pushNotificationsEnabled);
      setEmailNotifEnabled(userData.profile.emailNotificationsEnabled);
    }
  }, [userData]);

  useEffect(() => {
    if (profileSettingsData) {
      setPushNotifNewOrderStatusEnabled(
        profileSettingsData.find(
          (setting) => setting.key === 'newOrderStatusPush'
        )?.value === 'true'
      );

      setPushNotifNewDocumentEnabled(
        profileSettingsData.find((setting) => setting.key === 'newDocumentPush')
          ?.value === 'true'
      );

      setPushNotifNewOrderEnabled(
        profileSettingsData.find((setting) => setting.key === 'newOrderPush')
          ?.value === 'true'
      );

      setPushNotifNewGraphicsDraftEnabled(
        profileSettingsData.find(
          (setting) => setting.key === 'newGraphicsDraftPush'
        )?.value === 'true'
      );

      setEmailNotifNewOrderStatusEnabled(
        profileSettingsData.find(
          (setting) => setting.key === 'newOrderStatusEmail'
        )?.value === 'true'
      );

      setEmailNotifNewDocumentEnabled(
        profileSettingsData.find(
          (setting) => setting.key === 'newDocumentEmail'
        )?.value === 'true'
      );

      setEmailNotifNewOrderEnabled(
        profileSettingsData.find((setting) => setting.key === 'newOrderEmail')
          ?.value === 'true'
      );

      setEmailNotifNewGraphicsDraftEnabled(
        profileSettingsData.find(
          (setting) => setting.key === 'newGraphicsDraftEmail'
        )?.value === 'true'
      );
    }
  }, [profileSettingsData]);

  const handleSettingsChange = async (key: string, value: boolean) => {
    switch (key) {
      case 'newOrderStatusPush':
      case 'newDocumentPush':
      case 'newOrderPush':
      case 'newGraphicsDraftPush':
      case 'newOrderStatusEmail':
      case 'newDocumentEmail':
      case 'newOrderEmail':
      case 'newGraphicsDraftEmail':
        try {
          await modifyProfileSettings([
            {
              key,
              value: value.toString(),
            },
          ]).unwrap();
        } catch (e) {
          console.error(e);
        }
        break;
      case 'pushNotifEnabled':
        if (value === true) {
          // Request notification permission

          if (Notification.permission === 'denied') {
            showNotification({
              title: 'Notifiche disabilitate',
              message: 'Le notifiche sono state disabilitate dal browser.',
            });
          }

          const permission = await Notification.requestPermission();

          if (permission === 'granted') {
            try {
              await modifyUser({ pushNotificationsEnabled: true }).unwrap();
            } catch (e) {
              console.error(e);
            }
          }
        } else {
          try {
            await modifyUser({ pushNotificationsEnabled: false }).unwrap();
          } catch (e) {
            console.error(e);
          }
        }

        break;
      case 'emailNotifEnabled':
        try {
          await modifyUser({ emailNotificationsEnabled: value }).unwrap();
        } catch (e) {
          console.error(e);
        }
        break;
    }
  };

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleLogoutClick = async () => {
    await logoutFun().unwrap();
    dispatch(logout());
    navigate('/');
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Layout
      title="Impostazioni account"
      loading={isLoadingUser || isLoadingSeller || isLoadingProfileSettings}
    >
      <Group spacing="xl" align="flex-start" position="center" grow>
        <Card
          fullWidthBreakpoint={1030}
          title="Informazioni account"
          action={
            !isEditingProfileInfo && !isEditingPassword
              ? {
                  title: 'Modifica',
                  icon: <Pencil />,
                  onClick: () => setIsEditingProfileInfo(!isEditingProfileInfo),
                }
              : undefined
          }
        >
          {isEditingProfileInfo ? (
            <EditProfileInformationForm
              email={userData?.email}
              name={userData?.profile.name}
              phone={userData?.profile.phone}
              vatTaxNumber={userData?.profile.vatTaxNumber}
              pec={userData?.profile.pec}
              sdi={userData?.profile.sdi}
              type={userData?.profile.type}
              onClose={() => setIsEditingProfileInfo(false)}
            />
          ) : isEditingPassword ? (
            <EditUserPasswordForm onClose={() => setIsEditingPassword(false)} />
          ) : (
            <>
              <LoadingText loading={userData === undefined}>
                <strong>Email: </strong>
                {userData?.email}
              </LoadingText>
              <LoadingText loading={userData === undefined}>
                <strong>
                  {userData?.profile.type === 'company'
                    ? 'P. Iva / Codice fiscale: '
                    : 'Codice fiscale: '}
                </strong>
                {userData?.profile.vatTaxNumber}
              </LoadingText>
              <LoadingText loading={userData === undefined}>
                <strong>Nome: </strong>
                {userData?.profile.name}
              </LoadingText>
              <LoadingText loading={userData === undefined}>
                <strong>Telefono: </strong>
                {userData?.profile.phone}
              </LoadingText>
              {userData?.profile.type === 'company' && (
                <>
                  <LoadingText loading={userData === undefined}>
                    <strong>Pec: </strong>
                    {userData?.profile.pec || '-'}
                  </LoadingText>
                  <LoadingText loading={userData === undefined}>
                    <strong>SDI: </strong>
                    {userData?.profile.sdi}
                  </LoadingText>
                </>
              )}
              <Button mt="md" onClick={() => setIsEditingPassword(true)}>
                Modifica password
              </Button>
            </>
          )}
        </Card>
        <Card
          fullWidthBreakpoint={1030}
          title="Indirizzo di fatturazione"
          action={
            !isEditingAddress
              ? {
                  title: 'Modifica',
                  icon: <Pencil />,
                  onClick: () => setIsEditingAddress(!isEditingAddress),
                }
              : undefined
          }
        >
          {isEditingAddress ? (
            <EditAddressForm
              address={userData?.profile.billingAddress.address}
              zip={userData?.profile.billingAddress.zip}
              city={userData?.profile.billingAddress.city}
              province={userData?.profile.billingAddress.province}
              onClose={() => setIsEditingAddress(false)}
            />
          ) : (
            <>
              <LoadingText loading={userData === undefined}>
                {userData?.profile.billingAddress.address}
              </LoadingText>
              <LoadingText loading={userData === undefined}>
                {userData?.profile.billingAddress.zip} -{' '}
                {userData?.profile.billingAddress.city} (
                {userData?.profile.billingAddress.province})
              </LoadingText>
              <LoadingText loading={userData === undefined}>
                {userData?.profile.billingAddress.nation}
              </LoadingText>
            </>
          )}
        </Card>
        <Card fullWidthBreakpoint={1030} title="Riferimento commerciale">
          {sellerData === null ? (
            <Text>Nessun commerciale assegnato.</Text>
          ) : (
            <>
              <LoadingText loading={sellerData === undefined}>
                <strong>Nominativo: </strong>
                {sellerData?.name}
              </LoadingText>
              <LoadingText loading={sellerData === undefined}>
                <strong>Email: </strong>
                <Anchor href={`mailto:${sellerData?.email}`}>
                  {sellerData?.email}
                </Anchor>
              </LoadingText>
              <LoadingText loading={sellerData === undefined}>
                <strong>Telefono: </strong>
                <Anchor href={`tel:${sellerData?.phone}`}>
                  {sellerData?.phone}
                </Anchor>
              </LoadingText>
            </>
          )}
        </Card>
      </Group>

      <Group spacing="xl" align="flex-start" mt="xl">
        <Card title="Impostazioni notifiche">
          <Text style={{ maxWidth: '25em' }} mb="sm" size="sm" align="justify">
            Abilita o disabilita la ricezione di notifiche push o email e
            personalizza la tipologia di notifiche che desideri ricevere.
          </Text>
          <Group spacing="xl">
            <div>
              <Text mb="md">
                <strong>Notifiche push</strong>
              </Text>
              <Switch
                label="Abilita"
                mb="xs"
                disabled={isModifyUserLoading || isModifyProfileSettingsLoading}
                checked={pushNotifEnabled}
                onChange={(e) =>
                  handleSettingsChange(
                    'pushNotifEnabled',
                    e.currentTarget.checked
                  )
                }
              />
              <Divider my="sm" />
              <Switch
                label="Nuovo ordine"
                mb="xs"
                disabled={!pushNotifEnabled}
                checked={pushNotifNewOrderEnabled}
                onChange={(e) =>
                  handleSettingsChange('newOrderPush', e.currentTarget.checked)
                }
              />
              <Switch
                label="Nuovo stato ordine"
                mb="xs"
                disabled={!pushNotifEnabled}
                checked={pushNotifNewOrderStatusEnabled}
                onChange={(e) =>
                  handleSettingsChange(
                    'newOrderStatusPush',
                    e.currentTarget.checked
                  )
                }
              />
              <Switch
                label="Nuova bozza grafica"
                mb="xs"
                disabled={!pushNotifEnabled}
                checked={pushNotifNewGraphicsDraftEnabled}
                onChange={(e) =>
                  handleSettingsChange(
                    'newGraphicsDraftPush',
                    e.currentTarget.checked
                  )
                }
              />
              <Switch
                label="Nuovo documento"
                disabled={!pushNotifEnabled}
                checked={pushNotifNewDocumentEnabled}
                onChange={(e) =>
                  handleSettingsChange(
                    'newDocumentPush',
                    e.currentTarget.checked
                  )
                }
              />
            </div>
            <div>
              <Text mb="md">
                <strong>Notifiche email</strong>
              </Text>
              <Switch
                label="Abilita"
                mb="xs"
                disabled={isModifyUserLoading || isModifyProfileSettingsLoading}
                checked={emailNotifEnabled}
                onChange={(e) =>
                  handleSettingsChange(
                    'emailNotifEnabled',
                    e.currentTarget.checked
                  )
                }
              />
              <Divider my="sm" />
              <Switch
                label="Nuovo ordine"
                mb="xs"
                disabled={!emailNotifEnabled}
                checked={emailNotifNewOrderEnabled}
                onChange={(e) =>
                  handleSettingsChange('newOrderEmail', e.currentTarget.checked)
                }
              />
              <Switch
                label="Nuovo stato ordine"
                mb="xs"
                disabled={!emailNotifEnabled}
                checked={emailNotifNewOrderStatusEnabled}
                onChange={(e) =>
                  handleSettingsChange(
                    'newOrderStatusEmail',
                    e.currentTarget.checked
                  )
                }
              />
              <Switch
                label="Nuova bozza grafica"
                mb="xs"
                disabled={!emailNotifEnabled}
                checked={emailNotifNewGraphicsDraftEnabled}
                onChange={(e) =>
                  handleSettingsChange(
                    'newGraphicsDraftEmail',
                    e.currentTarget.checked
                  )
                }
              />
              <Switch
                label="Nuovo documento"
                disabled={!emailNotifEnabled}
                checked={emailNotifNewDocumentEnabled}
                onChange={(e) =>
                  handleSettingsChange(
                    'newDocumentEmail',
                    e.currentTarget.checked
                  )
                }
              />
            </div>
          </Group>
        </Card>
      </Group>

      <Button
        color="red"
        mt="lg"
        onClick={handleLogoutClick}
        loading={isLoading}
      >
        Logout
      </Button>
    </Layout>
  );
};

export default Account;
