import { MantineThemeOverride } from '@mantine/core';

const theme: MantineThemeOverride = {
  fontFamily: 'Inter, sans-serif',
  headings: {
    fontFamily: 'Inter, sans-serif',
    sizes: {
      h1: {
        fontSize: '1.5rem',
      },
      h2: {
        fontSize: '1.2rem',
      },
    },
  },

  black: '#55607c',

  colors: {
    brand: [
      '#E2D7CD',
      '#D7C3B0',
      '#D1B092',
      '#D1A072',
      '#D8904E',
      '#E78125',
      '#F37400',
      '#C56916',
      '#A16023',
      '#86572B',
    ],
  },
  primaryColor: 'brand',

  defaultRadius: 'md',

  loader: 'dots',

  components: {
    ActionIcon: {
      styles: {
        root: { color: '#55607c' },
      },
    },
    Paper: {
      defaultProps: {
        withBorder: true,
        shadow: 'md',
      },
    },
    Switch: {
      styles: {
        track: {
          cursor: 'pointer',
        },
      },
    },
  },
};

export default theme;
