import { createApi } from '@reduxjs/toolkit/query/react';

import { SortOrder } from '@domain/types';

import { baseQuery } from './';

interface Ddt {
  id: string;
  date: string;
  pdfName: string;
  userId: number;
}

interface DdtCount {
  count: number;
}

export type SortBy = 'id' | 'date';

export interface DdtApiQueryParams {
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  page?: number;
  pageLength?: number;
}

const api = createApi({
  baseQuery: baseQuery,
  reducerPath: 'api/ddt',
  endpoints: (builder) => ({
    getDdt: builder.query<Ddt[], DdtApiQueryParams>({
      query: (params) => ({
        url: 'ddt',
        params,
      }),
    }),
    getDdtCount: builder.query<DdtCount, void>({
      query: () => ({
        url: 'ddt/count',
      }),
    }),
  }),
});

export const { useGetDdtQuery, useGetDdtCountQuery } = api;
export default api;
