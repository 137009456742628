import { FC, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { Badge, Button } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';

import { ORDER_STATUS_COLORS, ORDER_STATUS_STRINGS } from '@constants/orderStatus';

import { SortOrder } from '@domain/types';

import {
  OrderApiQueryParams,
  SortBy,
  useGetOrdersCountQuery,
  useGetOrdersQuery
} from '@api/ordersApi';

import Layout from '@components/layout/Layout';
import PaginationRow from '@components/PaginationRow';
import SearchBar from '@components/SearchBar';
import SortableTable from '@components/sortableTable/SortableTable';

const Orders: FC = () => {
  const [searchParams] = useSearchParams();

  // ==========================================================================
  // State
  // ==========================================================================
  const [filters, setFilters] = useState<OrderApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 5),
    searchQuery: '',
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  // ==========================================================================
  // Api
  // ==========================================================================

  // Get initial orders count
  const { data: ordersCount = { count: 0 }, isLoading: isLoadingOrdersCount } =
    useGetOrdersCountQuery(searchQuery);

  const { data = [], isLoading } = useGetOrdersQuery({
    ...filters,
    searchQuery,
  });

  // Map data
  const orders = data.map((order) => {
    const data = [
      new Date(order.date).toLocaleDateString('IT-it'),
      order.id,
      order.customerRef || '-',
      order.shippingDate
        ? new Date(order.shippingDate).toLocaleDateString('IT-it')
        : '-',

      <Badge color={ORDER_STATUS_COLORS[order.status]}>
        {ORDER_STATUS_STRINGS[order.status]}
      </Badge>,
      <Button to={`/ordini/${order.id}`} component={Link}>
        Dettagli
      </Button>,
    ];

    return {
      key: order.id,
      data,
    };
  });

  // ==========================================================================
  // Render
  // ==========================================================================
  const totalPages = Math.ceil(ordersCount.count / filters.pageLength!);

  return (
    <Layout title="Lista ordini" loading={isLoading || isLoadingOrdersCount}>
      <SearchBar
        placeholder="Ricerca per numero ordine o riferimento cliente"
        value={filters.searchQuery}
        onChange={(newValue) =>
          setFilters({ ...filters, searchQuery: newValue })
        }
      />
      <SortableTable
        data={orders}
        headings={{
          date: 'Data',
          id: 'Numero ordine',
          customerRef: 'Rif. cliente',
          shippingDate: 'Data sped. prevista',
          status: 'Stato',
          details: '',
        }}
        sortableKeys={['id', 'date']}
        onSortingChange={(key: string, order: SortOrder) =>
          setFilters({ ...filters, sortBy: key as SortBy, sortOrder: order })
        }
        emptyText="Nessun ordine trovato"
        loading={isLoading || isLoadingOrdersCount}
      />
      {orders.length > 0 && (
        <PaginationRow
          page={filters.page!}
          pageLength={filters.pageLength!}
          totalPages={totalPages}
          onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
          onPageLengthChange={(newPageLength) =>
            setFilters({ ...filters, pageLength: newPageLength, page: 1 })
          }
        />
      )}
    </Layout>
  );
};

export default Orders;
