import { createApi } from '@reduxjs/toolkit/query/react';

import { PaymentType, ShippingMethod, SortOrder } from '@domain/types';

import { baseQuery } from './';

type OrderStatus =
  | 'inserted'
  | 'waitingConfirmation'
  | 'waitingPayment'
  | 'confirmed'
  | 'production'
  | 'closed';

export interface Order {
  id: string;
  date: string;
  customerRef: string | null;
  shippingDate: string | null;
  trackingUrl: string[] | null;
  status: OrderStatus;
  paymentType: PaymentType;
  shippingMethod: ShippingMethod;
  total: number | null;
}

export interface OrderWithAddressAndTotal extends Order {
  productsPricesSum: number;
  deliveryAddress: {
    name: string;
    address: string;
    city: string;
    zip: string;
    province: string;
    nation: string;
  };
}

interface OrderCount {
  count: number;
}

interface ProductsCount {
  count: number;
}

export type SortBy = 'id' | 'date';

export interface OrderApiQueryParams {
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
}

type OrderProductStatus =
  | 'inserted'
  | 'checkingAvailability'
  | 'waitingConfirmation'
  | 'waitingPayment'
  | 'confirmed'
  | 'production'
  | 'waitingGraphicsConfirmation'
  | 'graphicsConfirmed'
  | 'readyToShip'
  | 'closed'
  | 'cancelled';

export interface Product {
  id: string;
  productCode: string;
  name: string;
  imageUrl: string;
  color?: string;
  size?: string;
  quantity: number;
  unitPrice: number;
  salePercent?: number;
  shippingDate?: string;
  draftProductsRelationId?: string;
  status: OrderProductStatus;
}

export interface OrderProductApiQueryParams {
  id: string;
  params: OrderApiQueryParams;
}

export interface OrderProductsCountApiQueryParams {
  id: string;
  searchQuery?: string;
}

export interface ConfirmOrderRequest {
  id: string;
}

export interface ConfirmPaymentTypeRequest {
  id: string;
}

export interface ConfirmProductGraphicRequest {
  id: string;
}

export interface CreateStripeOrderRequest {
  id: string;
}

export interface CreateStripeOrderResponse {
  clientSecret: string;
}

export interface CreatePaypalOrderRequest {
  id: string;
}

export interface CreatePaypalOrderResponse {
  id: string;
}

export interface ConfirmPaypalPaymentRequest {
  id: string;
  body: {
    paypalOrderId: string;
  };
}

export interface ConfirmPaypalPaymentResponse {
  message: string;
}

export interface ModifyOrderRequest {
  id: string;
  body: {
    address: string;
    city: string;
    zip: string;
    province: string;
  };
}

interface GetWebOrderIdResponse {
  id: string;
}

const api = createApi({
  baseQuery: baseQuery,
  reducerPath: 'api/orders',
  tagTypes: ['Order', 'Products'],
  endpoints: (builder) => ({
    getOrders: builder.query<Order[], OrderApiQueryParams>({
      query: (params) => ({
        url: 'orders',
        params,
      }),
    }),
    getOrdersCount: builder.query<OrderCount, string | undefined>({
      query: (searchQuery) => ({
        url: 'orders/count',
        params: { searchQuery },
      }),
    }),
    getSingleOrder: builder.query<OrderWithAddressAndTotal, string>({
      query: (id) => ({
        url: `orders/${id}`,
      }),
      providesTags: ['Order'],
    }),
    getOrderProductsCount: builder.query<
      ProductsCount,
      OrderProductsCountApiQueryParams
    >({
      query: ({ id, searchQuery }) => ({
        url: `orders/${id}/products/count`,
        params: { searchQuery },
      }),
    }),
    getOrderProducts: builder.query<Product[], OrderProductApiQueryParams>({
      query: ({ id, params }) => ({
        url: `orders/${id}/products`,
        params,
      }),
      providesTags: ['Products'],
    }),
    confirmOrder: builder.mutation<Order, ConfirmOrderRequest>({
      query: ({ id }) => ({
        url: `orders/${id}/confirm`,
        method: 'POST',
      }),
      invalidatesTags: ['Order', 'Products'],
    }),
    confirmPaymentType: builder.mutation<Order, ConfirmPaymentTypeRequest>({
      query: ({ id }) => ({
        url: `orders/${id}/confirm-payment-type`,
        method: 'POST',
      }),
      invalidatesTags: ['Order', 'Products'],
    }),
    createStripeOrder: builder.mutation<
      CreateStripeOrderResponse,
      CreateStripeOrderRequest
    >({
      query: ({ id }) => ({
        url: `orders/${id}/stripe-create-order`,
        method: 'POST',
      }),
    }),
    createPaypalOrder: builder.mutation<
      CreatePaypalOrderResponse,
      CreatePaypalOrderRequest
    >({
      query: ({ id }) => ({
        url: `orders/${id}/paypal-create-order`,
        method: 'POST',
      }),
    }),
    confirmPaypalPayment: builder.mutation<
      ConfirmPaypalPaymentResponse,
      ConfirmPaypalPaymentRequest
    >({
      query: ({ id, body }) => ({
        url: `orders/${id}/paypal-confirm-payment`,
        method: 'POST',
        body,
      }),
    }),
    modifyOrder: builder.mutation<Order, ModifyOrderRequest>({
      query: ({ id, body }) => ({
        url: `orders/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Order'],
    }),
    cancelProduct: builder.mutation<Product, string>({
      query: (id) => ({
        url: `products/${id}/cancel`,
        method: 'POST',
      }),
      invalidatesTags: ['Products'],
    }),
    getWebOrderId: builder.query<GetWebOrderIdResponse, number>({
      query: (id) => ({
        url: `orders/web/${id}`,
      }),
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetOrdersCountQuery,
  useGetSingleOrderQuery,
  useGetOrderProductsCountQuery,
  useGetOrderProductsQuery,
  useConfirmOrderMutation,
  useConfirmPaymentTypeMutation,
  useCreateStripeOrderMutation,
  useCreatePaypalOrderMutation,
  useConfirmPaypalPaymentMutation,
  useModifyOrderMutation,
  useCancelProductMutation,
  useGetWebOrderIdQuery,
} = api;

export default api;
