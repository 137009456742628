import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './';

export interface Province {
  nome: string;
  sigla: string;
  regione: string;
}

export interface Comuni {
  nome: string;
  codice: string;
  zona: { codice: string; nome: string };
  regione: { codice: string; nome: string };
  provincia: { codice: string; nome: string };
  sigla: string;
  codiceCatastale: string;
  cap: string[];
  popolazione: number;
}

const api = createApi({
  baseQuery: baseQuery,
  reducerPath: 'api/locations',
  endpoints: (builder) => ({
    getProvince: builder.query<Province[], void>({
      query: () => ({
        url: '/locations/province',
      }),
    }),
    getCitta: builder.query<Comuni[], string>({
      query: (sigla) => ({
        url: `/locations/province/${sigla}/citta`,
      }),
    }),
  }),
});

export const { useGetProvinceQuery, useGetCittaQuery } = api;
export default api;
