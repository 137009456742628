import { FC, Fragment, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Divider, Group, LoadingOverlay, Paper, Select, Text } from '@mantine/core';

import {
  NotificationsApiQueryParams,
  NotificationsFilter,
  useGetNotificationsCountQuery,
  useGetNotificationsQuery,
  useModifyNotificationMutation
} from '@api/notificationsApi';

import Layout from '@components/layout/Layout';
import NotificationCard from '@components/notificationCard/NotificationCard';
import PaginationRow from '@components/PaginationRow';

import documentsIcon from '@images/icons/documents-icon.svg';
import notificationIcon from '@images/icons/notification-icon.svg';
import ordersIcon from '@images/icons/orders-icon.svg';

const Notifications: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  // ==========================================================================
  // State
  // ==========================================================================
  const [filters, setFilters] = useState<NotificationsApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 5),
    filter: 'notArchived',
  });

  // ==========================================================================
  // Api
  // ==========================================================================

  // Get initial notifications count
  const {
    data: notificationsCount = { count: 0 },
    isLoading: isLoadingNotificationsCount,
  } = useGetNotificationsCountQuery(filters.filter);

  const { data = [], isLoading } = useGetNotificationsQuery(filters);

  const [modifyNotification] = useModifyNotificationMutation();

  // Map data
  const notifications = data.map((notification, index) => (
    <Fragment key={notification.id}>
      <NotificationCard
        title={notification.title}
        description={notification.description}
        read={notification.read}
        date={new Date(notification.createdAt)}
        image={
          notification.category === 'orderStatusChange' ||
          notification.category === 'newOrder' ||
          notification.category === 'newGraphicsDraft'
            ? ordersIcon
            : notification.category === 'other'
            ? notificationIcon
            : documentsIcon
        }
        onClick={() => {
          if (!notification.read) {
            modifyNotification({
              id: notification.id,
              body: { read: true },
            }).unwrap();
          }

          switch (notification.category) {
            case 'orderStatusChange':
            case 'newOrder':
            case 'newGraphicsDraft':
              navigate(`/ordini/${notification.orderId}`);
              break;
            case 'newDocumentDdt':
              navigate(`/documenti/ddt?sel=${notification.dDTId}`);
              break;
            case 'newDocumentInvoice':
              navigate(`/documenti/fatture?sel=${notification.invoiceId}`);
              break;
            case 'newDocumentOffer':
              navigate(`/offerte/${notification.offerId}`);
              break;
            case 'other':
            default:
              // Nothing to do
              break;
          }
        }}
      />
      {index !== data.length - 1 && <Divider />}
    </Fragment>
  ));

  const totalPages = Math.ceil(notificationsCount.count / filters.pageLength!);

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Layout title="Notifiche">
      <Group mb="lg">
        <Select
          label="Mostra"
          data={[
            { label: 'Tutte', value: 'notArchived' },
            { label: 'Non lette', value: 'notRead' },
            { label: 'Lette', value: 'read' },
            { label: 'Archiviate', value: 'archived' },
          ]}
          value={filters.filter}
          onChange={(value) =>
            setFilters({ ...filters, filter: value as NotificationsFilter })
          }
        />
      </Group>
      <Paper px="lg" py="sm">
        <LoadingOverlay visible={isLoading || isLoadingNotificationsCount} />
        {notifications.length > 0 ? (
          notifications
        ) : (
          <Text align="center" weight="bold" my="lg">
            Nessuna notifica presente per i filtri selezionati
          </Text>
        )}
      </Paper>
      {notifications.length > 0 && (
        <PaginationRow
          page={filters.page!}
          pageLength={filters.pageLength!}
          totalPages={totalPages}
          onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
          onPageLengthChange={(newPageLength) =>
            setFilters({ ...filters, pageLength: newPageLength, page: 1 })
          }
        />
      )}
    </Layout>
  );
};

export default Notifications;
