import { createApi } from '@reduxjs/toolkit/query/react';

import { SortOrder } from '@domain/types';

import { baseQuery } from './';

type NotificationsCategory =
  | 'newOrder'
  | 'orderStatusChange'
  | 'newGraphicsDraft'
  | 'newDocumentDdt'
  | 'newDocumentInvoice'
  | 'newDocumentOffer'
  | 'other';

interface Notifications {
  id: number;
  title: string;
  description: string;
  category: NotificationsCategory;
  read: boolean;
  archived: boolean;
  orderId: string | null;
  offerId: string | null;
  dDTId: string | null;
  invoiceId: string | null;
  createdAt: string;
  userId: number;
}

export type NotificationsFilter =
  | 'read'
  | 'notRead'
  | 'archived'
  | 'notArchived'
  | 'all';

export interface NotificationsApiQueryParams {
  sortOrder?: SortOrder;
  page?: number;
  pageLength?: number;
  filter?: NotificationsFilter;
}

interface NotificationsCount {
  count: number;
}

interface ModifyNotificationRequest {
  id: number;
  body: {
    read?: boolean;
    archived?: boolean;
  };
}

const api = createApi({
  baseQuery: baseQuery,
  reducerPath: 'api/notifications',
  tagTypes: ['Notifications'],
  endpoints: (builder) => ({
    getNotificationsCount: builder.query<
      NotificationsCount,
      NotificationsFilter | undefined
    >({
      query: (filter) => ({
        url: 'notifications/count',
        params: { filter },
      }),
    }),
    getNotifications: builder.query<
      Notifications[],
      NotificationsApiQueryParams
    >({
      query: (params) => ({
        url: '/notifications',
        params,
      }),
      providesTags: ['Notifications'],
    }),
    modifyNotification: builder.mutation<
      Notification,
      ModifyNotificationRequest
    >({
      query: ({ id, body }) => ({
        url: `notifications/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Notifications'],
    }),
  }),
});

export const {
  useGetNotificationsCountQuery,
  useGetNotificationsQuery,
  useModifyNotificationMutation,
} = api;
export default api;
