import { FC, ReactNode } from 'react';

import { MantineNumberSize, Skeleton, Text } from '@mantine/core';

interface LoadingTextProps {
  loading: boolean;
  skeletonHeight?: number;
  skeletonWidth?: number;
  skeletonMarginTop?: MantineNumberSize;
  skeletonMarginBottom?: MantineNumberSize;
  skeletonRadius?: MantineNumberSize;
  children?: ReactNode;
  [x: string]: any;
}

const LoadingText: FC<LoadingTextProps> = ({
  loading,
  children,
  skeletonHeight = 15,
  skeletonWidth = 150,
  skeletonMarginTop = 0,
  skeletonMarginBottom = 'xs',
  skeletonRadius = 'md',
  ...otherProps
}) => {
  // ==========================================================================
  // Render
  // ==========================================================================
  return loading ? (
    <Skeleton
      height={skeletonHeight}
      width={skeletonWidth}
      mt={skeletonMarginTop}
      mb={skeletonMarginBottom}
      radius={skeletonRadius}
    />
  ) : (
    <Text {...otherProps}>{children}</Text>
  );
};

export default LoadingText;
