import { createStyles } from '@mantine/core';

const HEADER_HEIGHT = 60;

const useStyles = createStyles((theme) => ({
  header: {
    height: HEADER_HEIGHT,
  },

  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  link: {
    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },

    [theme.fn.smallerThan('lg')]: {
      paddingLeft: theme.spacing.xs,
      paddingRight: theme.spacing.xs,
    },

    '.mantine-Button-label': {
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    },
  },

  accountLink: {
    [theme.fn.smallerThan('lg')]: {
      '.mantine-Button-label': {
        display: 'none',
      },
    },
  },

  userName: {
    lineHeight: 1.3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '10rem',
  },

  badge: {
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

export default useStyles;
