import { FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FileText } from 'tabler-icons-react';

import { Anchor } from '@mantine/core';

import env from '@config/env';

import { SortOrder } from '@domain/types';

import { DdtApiQueryParams, SortBy, useGetDdtCountQuery, useGetDdtQuery } from '@api/ddtApi';

import Layout from '@components/layout/Layout';
import PaginationRow from '@components/PaginationRow';
import SortableTable from '@components/sortableTable/SortableTable';

const Ddt: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams] = useSearchParams();

  const selectedDdt = searchParams.get('sel');

  // ==========================================================================
  // State
  // ==========================================================================

  const [filters, setFilters] = useState<DdtApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 5),
  });

  // ==========================================================================
  // Api
  // ==========================================================================

  // Get initial orders count
  const { data: ddtCount = { count: 0 }, isLoading: isLoadingDdtCount } =
    useGetDdtCountQuery();

  const { data = [], isLoading } = useGetDdtQuery({
    ...filters,
  });

  // Map data
  const ddt = data.map((ddt) => {
    const data = [
      ddt.id === selectedDdt ? (
        <strong>{new Date(ddt.date).toLocaleDateString('IT-it')}</strong>
      ) : (
        new Date(ddt.date).toLocaleDateString('IT-it')
      ),
      ddt.id === selectedDdt ? <strong>{ddt.id}</strong> : ddt.id,
      <Anchor
        href={`${env.RESOURCES_URL}/ddt/${ddt.pdfName}`}
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <FileText />
      </Anchor>,
    ];

    return {
      key: ddt.id,
      data,
    };
  });

  // ==========================================================================
  // Render
  // ==========================================================================
  const totalPages = Math.ceil(ddtCount.count / filters.pageLength!);

  return (
    <Layout
      title="Documenti di trasporto"
      loading={isLoading || isLoadingDdtCount}
    >
      <SortableTable
        data={ddt}
        headings={{
          date: 'Data',
          id: 'Numero DDT',
          pdfLink: 'Pdf',
        }}
        sortableKeys={['id', 'date']}
        onSortingChange={(key: string, order: SortOrder) =>
          setFilters({ ...filters, sortBy: key as SortBy, sortOrder: order })
        }
        emptyText="Nessun DDT trovato"
        loading={isLoading || isLoadingDdtCount}
      />
      {ddt.length > 0 && (
        <PaginationRow
          page={filters.page!}
          pageLength={filters.pageLength!}
          totalPages={totalPages}
          onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
          onPageLengthChange={(newPageLength) =>
            setFilters({ ...filters, pageLength: newPageLength, page: 1 })
          }
        />
      )}
    </Layout>
  );
};

export default Ddt;
