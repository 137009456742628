import { FC, ReactNode } from 'react';

import { Container, Text, Title } from '@mantine/core';

import logo from '@images/logo.svg';

import useStyles from './guestLayout.style';

interface GuestLayoutProps {
  size?: number;
  titleComponent?: ReactNode;
  children: ReactNode;
}

const GuestLayout: FC<GuestLayoutProps> = ({
  size = 420,
  titleComponent,
  children,
}) => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { classes } = useStyles();

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Container size={size} my={40}>
      <img src={logo} className={classes.logo} alt="Logo Tuoteam" />
      <Title align="center" sx={{ fontSize: '1.8rem' }} mt="md">
        Area riservata
      </Title>
      {titleComponent && (
        <Text color="dimmed" size="sm" align="center" mt={5}>
          {titleComponent}
        </Text>
      )}
      {children}
    </Container>
  );
};

export default GuestLayout;
