import { createApi } from '@reduxjs/toolkit/query/react';

import { PaymentType, SortOrder } from '@domain/types';

import { baseQuery } from './';

interface Offer {
  id: string;
  date: string;
  pdfName: string;
  paymentType: PaymentType;
  confirmationDate?: string;
  userId: number;
}

interface OffersCount {
  count: number;
}

export type SortBy = 'id' | 'date';

export interface OffersApiQueryParams {
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  page?: number;
  pageLength?: number;
}

export interface OfferProduct {
  id: string;
  productCode: string;
  name: string;
  imageUrl: string;
  color: string;
  size: string;
  quantity: number;
  unitPrice: number;
  salePercent?: number;
}

export interface OfferProductApiQueryParams {
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  page?: number;
  pageLength?: number;
  searchQuery?: string;
}

export interface OfferProductApiParams {
  id: string;
  params: OfferProductApiQueryParams;
}

export interface OfferProductsCountApiQueryParams {
  id: string;
  searchQuery?: string;
}

interface OfferProductsCount {
  count: number;
}

interface ConfirmOfferRequest {
  id: string;
}

const api = createApi({
  baseQuery: baseQuery,
  reducerPath: 'api/offers',
  tagTypes: ['offer'],
  endpoints: (builder) => ({
    getOffers: builder.query<Offer[], OffersApiQueryParams>({
      query: (params) => ({
        url: 'offers',
        params,
      }),
    }),
    getOffersCount: builder.query<OffersCount, void>({
      query: () => ({
        url: 'offers/count',
      }),
    }),
    getSingleOffer: builder.query<Offer, string>({
      query: (id) => ({
        url: `offers/${id}`,
      }),
      providesTags: ['offer'],
    }),
    getOfferProductsCount: builder.query<
      OfferProductsCount,
      OfferProductsCountApiQueryParams
    >({
      query: ({ id, searchQuery }) => ({
        url: `offers/${id}/products/count`,
        params: { searchQuery },
      }),
    }),
    getOfferProducts: builder.query<OfferProduct[], OfferProductApiParams>({
      query: ({ id, params }) => ({
        url: `offers/${id}/products`,
        params,
      }),
    }),
    confirmOffer: builder.mutation<Offer, ConfirmOfferRequest>({
      query: ({ id }) => ({
        url: `offers/${id}/confirm`,
        method: 'POST',
      }),
      invalidatesTags: ['offer'],
    }),
  }),
});

export const {
  useGetOffersQuery,
  useGetOffersCountQuery,
  useGetSingleOfferQuery,
  useGetOfferProductsCountQuery,
  useGetOfferProductsQuery,
  useConfirmOfferMutation,
} = api;
export default api;
