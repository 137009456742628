import { FC, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  Check,
  CircleCheck,
  CreditCard,
  ExternalLink,
  InfoCircle,
  Pencil,
} from 'tabler-icons-react';

import {
  ActionIcon,
  Anchor,
  Badge,
  Button,
  Group,
  MediaQuery,
  Modal,
  Popover,
  Stack,
  Stepper,
  Text,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import {
  CreateOrderActions,
  CreateOrderData,
  OnApproveActions,
  OnApproveData,
} from '@paypal/paypal-js/types';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import env from '@config/env';

import {
  ORDER_STATUS_COLORS,
  ORDER_STATUS_STRINGS,
} from '@constants/orderStatus';
import {
  PRODUCT_STATUS_COLORS,
  PRODUCT_STATUS_STRINGS,
} from '@constants/productStatus';

import { SortOrder } from '@domain/types';

import {
  OrderApiQueryParams,
  Product,
  SortBy,
  useCancelProductMutation,
  useConfirmOrderMutation,
  useConfirmPaymentTypeMutation,
  useConfirmPaypalPaymentMutation,
  useCreatePaypalOrderMutation,
  useCreateStripeOrderMutation,
  useGetOrderProductsCountQuery,
  useGetOrderProductsQuery,
  useGetSingleOrderQuery,
} from '@api/ordersApi';

import { NotFound } from '@pages';

import Card from '@components/card/Card';
import EditOrderAddress from '@components/EditOrderAddress';
import GraphicsDraftsReview from '@components/graphicsDraftReview/GraphicsDraftsReview';
import Layout from '@components/layout/Layout';
import LoadingText from '@components/LoadingText';
import PaginationRow from '@components/PaginationRow';
import PaymentForm from '@components/PaymentForm';
import SearchBar from '@components/SearchBar';
import SortableTable from '@components/sortableTable/SortableTable';

const stripePromise = loadStripe(env.STRIPE_PUBLIC_KEY);

const statusIndexMap = {
  inserted: 0,
  waitingConfirmation: 1,
  waitingPayment: 2,
  confirmed: 3,
  production: 4,
  closed: 6,
};

const OrderDetail: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { id } = useParams();

  const TRANSITION_DURATION = 200;

  const [searchParams] = useSearchParams();

  // ==========================================================================
  // State
  // ==========================================================================
  const [paymentModalOpened, setPaymentModalOpened] = useState(false);
  const [paymentClientSecret, setPaymentClientSecret] = useState<string | null>(
    null
  );

  const [graphicsModalOpened, setGraphicsModalOpened] = useState(false);
  const [modalGraphicsInfo, setModalGraphicsInfo] = useState<{
    productName: string;
    draftProductsRelationId: string;
  } | null>(null);

  const [filters, setFilters] = useState<OrderApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 5),
    searchQuery: '',
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  const [isEditingAddress, setIsEditingAddress] = useState<boolean>(false);

  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: orderData,
    isLoading,
    error,
    refetch: refetchOrder,
  } = useGetSingleOrderQuery(id!);

  const [confirmOrder, { isLoading: isLoadingConfirmOrder }] =
    useConfirmOrderMutation();

  const [confirmPaymentType, { isLoading: isLoadingConfirmPaymentType }] =
    useConfirmPaymentTypeMutation();

  const [cancelProduct, { isLoading: isCancelProductLoading }] =
    useCancelProductMutation();

  // Get initial products count
  const {
    data: productsCount = { count: 0 },
    isLoading: isLoadingProductsCount,
  } = useGetOrderProductsCountQuery({ id: id!, searchQuery });

  const {
    data: productsData = [],
    isLoading: isProductsLoading,
    refetch: refetchProducts,
  } = useGetOrderProductsQuery({
    id: id!,
    params: { ...filters, searchQuery },
  });

  const [createStripeOrder, { isLoading: isLoadingStripe }] =
    useCreateStripeOrderMutation();

  const [createPaypalOrder] = useCreatePaypalOrderMutation();

  const [confirmPaypalPayment] = useConfirmPaypalPaymentMutation();

  // Map data
  const products = productsData.map((product) => {
    const data = [
      <img
        src={product.imageUrl}
        alt={product.name}
        style={{ height: '2rem' }}
      />,
      product.productCode,
      product.name,
      product.color || '-',
      product.size || '-',
      product.quantity,

      `€ ${product.unitPrice.toFixed(2)}`,
      <Group spacing={3} noWrap={true}>
        <span>€</span>
        {product.salePercent && (
          <span>
            <span style={{ textDecoration: 'line-through' }}>
              {product.unitPrice * product.quantity}
            </span>
          </span>
        )}
        <span>
          {(
            product.unitPrice *
            product.quantity *
            (1 - (product.salePercent || 0) / 100)
          ).toFixed(2)}
        </span>
      </Group>,
      product.salePercent ? `${product.salePercent}%` : '-',
      product.shippingDate
        ? new Date(product.shippingDate).toLocaleDateString('IT-it')
        : '-',
      <>
        <Badge color={PRODUCT_STATUS_COLORS[product.status]}>
          {PRODUCT_STATUS_STRINGS[product.status]}
        </Badge>
        {product.status === 'waitingGraphicsConfirmation' && (
          <Button
            style={{ display: 'block' }}
            mt="xs"
            onClick={() => handleGraphicVerificationRequestClick(product)}
          >
            Verifica grafica
          </Button>
        )}
        {(product.status === 'inserted' ||
          product.status === 'checkingAvailability' ||
          product.status === 'waitingConfirmation') && (
          <Button
            style={{ display: 'block' }}
            mt="xs"
            variant="default"
            onClick={() => handleProductCancelRequestClick(product)}
            loading={isCancelProductLoading}
          >
            Rimuovi prodotto
          </Button>
        )}
      </>,
    ];

    return {
      key: product.id,
      data,
    };
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleGraphicVerificationRequestClick = (product: Product) => {
    setGraphicsModalOpened(true);

    setModalGraphicsInfo({
      productName: product.name,
      draftProductsRelationId: product.draftProductsRelationId!,
    });
  };

  const handleProductCancelRequestClick = async (product: Product) => {
    try {
      await cancelProduct(product.id).unwrap();

      showNotification({
        title: 'Prodotto rimosso',
        message: 'Il prodotto è stato annullato con successo.',
      });
    } catch (e) {
      console.error(e);

      showNotification({
        title: "Errore nell'annullamento del prodotto!",
        message: "Impossibile procedere con l'annullamento.",
        color: 'red',
      });
    }
  };

  const handleConfirmBankTransferClick = async () => {
    try {
      await confirmPaymentType({ id: id! }).unwrap();
    } catch (e) {
      console.error(e);

      showNotification({
        title: 'Errore!',
        message: 'Impossibile procedere. Riprova più tardi.',
        color: 'red',
      });
    }
  };

  const handlePayClick = async () => {
    try {
      const { clientSecret } = await createStripeOrder({ id: id! }).unwrap();

      setPaymentClientSecret(clientSecret);
      setPaymentModalOpened(true);
    } catch (e) {
      console.error(e);

      showNotification({
        title: 'Errore nella creazione del pagamento!',
        message: 'Impossibile procedere con il pagamento. Riprova più tardi.',
        color: 'red',
      });
    }
  };

  const handlePaypalCreateOrder = async (
    _data: CreateOrderData,
    _actions: CreateOrderActions
  ) => {
    try {
      return (await createPaypalOrder({ id: id! }).unwrap()).id;
    } catch (e) {
      console.error(e);

      showNotification({
        title: 'Errore nella creazione del pagamento!',
        message: 'Impossibile procedere con il pagamento. Riprova più tardi.',
        color: 'red',
      });
    }

    return '';
  };

  const handlePaypalApprove = async (
    data: OnApproveData,
    _actions: OnApproveActions
  ) => {
    try {
      await confirmPaypalPayment({
        id: id!,
        body: { paypalOrderId: data.orderID },
      }).unwrap();

      // Reload orders and products
      refetchOrder();
      refetchProducts();

      showNotification({
        title: 'Pagamento effettuato',
        message: 'Pagamento effettuato con successo.',
        icon: <Check />,
        color: 'teal',
      });
    } catch (e) {
      showNotification({
        title: 'Errore nel completamento del pagamento!',
        message: 'Impossibile procedere con il pagamento. Riprova più tardi.',
        color: 'red',
      });
    }
  };

  const handleConfirmOrderClick = async () => {
    try {
      await confirmOrder({ id: orderData!.id }).unwrap();
    } catch (e) {
      console.error(e);

      showNotification({
        title: "Errore nella conferma dell'ordine!",
        message: 'Impossibile procedere con la conferma.',
        color: 'red',
      });
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const headings = {
    imageUrl: '',
    productCode: 'Codice',
    name: 'Nome',
    color: 'Colore',
    size: 'Taglia',
    quantity: 'Qta.',
    unitPrice: 'Prezzo unitario (Iva In.)',
    totalPrice: 'Prezzo tot.',
    salePercent: 'Sconto',
    shippingDate: 'Data sped. prev.',
    status: 'Stato',
  };

  const totalPriceNoDiscount = orderData?.productsPricesSum || 0;

  const totalPrice =
    totalPriceNoDiscount *
    (orderData?.paymentType !== 'onlineOnConfirm' ? 0.97 : 1);

  const totalPages = Math.ceil(productsCount.count / filters.pageLength!);

  const stepperSteps = Object.keys(ORDER_STATUS_STRINGS).map((status) => (
    <Stepper.Step
      key={status}
      label={ORDER_STATUS_STRINGS[status as keyof typeof ORDER_STATUS_STRINGS]}
      color={ORDER_STATUS_COLORS[status as keyof typeof ORDER_STATUS_STRINGS]}
    />
  ));

  return error && (error as FetchBaseQueryError).status === 404 ? (
    <NotFound />
  ) : (
    <PayPalScriptProvider
      options={{
        'client-id': env.PAYPAL_CLIENT_ID,
        currency: 'EUR',
        'disable-funding':
          'card,paylater,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo',
      }}
    >
      <Modal
        fullScreen
        opened={graphicsModalOpened}
        onClose={() => setGraphicsModalOpened(false)}
        title="Verifica grafica prodotto"
        transitionDuration={TRANSITION_DURATION}
        styles={{
          modal: {
            display: 'flex',
            flexDirection: 'column',
          },
          body: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <GraphicsDraftsReview
          productName={modalGraphicsInfo?.productName}
          draftProductsRelationId={modalGraphicsInfo?.draftProductsRelationId}
          closeModal={() => setGraphicsModalOpened(false)}
          transitionDuration={TRANSITION_DURATION}
        />
      </Modal>

      <Modal
        opened={paymentModalOpened}
        onClose={() => setPaymentModalOpened(false)}
        title="Pagamento ordine"
      >
        {paymentClientSecret && (
          <Elements
            options={{
              clientSecret: paymentClientSecret,
              appearance: {
                theme: 'stripe',
              },
            }}
            stripe={stripePromise}
          >
            <PaymentForm
              total={totalPrice}
              onSuccess={() => {
                setPaymentModalOpened(false);
                // Reload orders and products after some time
                setTimeout(() => {
                  refetchOrder();
                  refetchProducts();
                }, 1000);
              }}
            />
          </Elements>
        )}
      </Modal>

      <Layout
        title={`Dettagli ordine #${id}`}
        backLink={{ title: 'Lista ordini', to: '/ordini' }}
        loading={isLoading}
      >
        <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
          <Stepper
            mt={40}
            mb={50}
            color="gray"
            active={statusIndexMap[orderData?.status || 'inserted']}
            size="xs"
            iconSize={30}
            styles={{
              step: {
                flexDirection: 'column',
              },
              stepBody: {
                marginLeft: '0',
                marginTop: '7px',
              },
            }}
          >
            {stepperSteps}
          </Stepper>
        </MediaQuery>

        <Group spacing="xl" align="flex-start" position="center" grow mb={70}>
          <Card fullWidthBreakpoint={1190} title="Informazioni ordine">
            <LoadingText loading={orderData === undefined} mb="xs">
              <strong>Numero ordine: </strong>
              {orderData?.id}
            </LoadingText>
            <LoadingText loading={orderData === undefined} mb="xs">
              <strong>Riferimento cliente: </strong>
              {orderData?.customerRef || '-'}
            </LoadingText>
            <LoadingText loading={orderData === undefined}>
              <strong>Data: </strong>
              {new Date(orderData?.date || 0).toLocaleDateString('IT-it')}
            </LoadingText>
            {orderData?.status === 'waitingConfirmation' && (
              <Button
                leftIcon={<CircleCheck />}
                mt="lg"
                onClick={handleConfirmOrderClick}
                loading={isLoadingConfirmOrder}
              >
                Conferma ordine
              </Button>
            )}
          </Card>
          {orderData?.shippingMethod === 'courier' && (
            <Card
              fullWidthBreakpoint={1190}
              title="Indirizzo di consegna"
              action={
                orderData.status === 'inserted' && !isEditingAddress
                  ? {
                      title: 'Modifica',
                      icon: <Pencil />,
                      onClick: () => {
                        setIsEditingAddress(!isEditingAddress);
                      },
                    }
                  : undefined
              }
            >
              {isEditingAddress ? (
                <EditOrderAddress
                  onClose={() => setIsEditingAddress(false)}
                  address={orderData.deliveryAddress.address}
                  city={orderData.deliveryAddress.city}
                  zip={orderData.deliveryAddress.zip}
                  province={orderData.deliveryAddress.province}
                  orderId={id!}
                />
              ) : (
                <>
                  <LoadingText loading={orderData === undefined}>
                    {orderData?.deliveryAddress.address}
                  </LoadingText>
                  <LoadingText loading={orderData === undefined}>
                    {orderData?.deliveryAddress.zip} -{' '}
                    {orderData?.deliveryAddress.city} (
                    {orderData?.deliveryAddress.province}),{' '}
                    {orderData?.deliveryAddress.nation}
                  </LoadingText>
                </>
              )}
            </Card>
          )}
          <Card fullWidthBreakpoint={1190} title="Spedizione e pagamento">
            <LoadingText loading={orderData === undefined} mb="xs">
              <strong>Tipologia spedizione: </strong>
              {orderData?.shippingMethod === 'courier'
                ? 'Corriere'
                : 'Ritiro in sede'}
            </LoadingText>
            {orderData?.shippingMethod === 'courier' && (
              <>
                <LoadingText loading={orderData === undefined} mb="xs">
                  <strong>Data spedizione prevista: </strong>
                  {orderData?.shippingDate
                    ? new Date(orderData!.shippingDate).toLocaleDateString(
                        'IT-it'
                      )
                    : 'Non disponibile'}
                </LoadingText>
                <LoadingText loading={orderData === undefined} mb="xs">
                  <strong>Tracciamento spedizione: </strong>
                  {orderData?.trackingUrl && orderData.trackingUrl.length > 0
                    ? orderData.trackingUrl.map((url, i) => (
                        <Anchor
                          key={url}
                          href={url}
                          style={{
                            display:
                              orderData!.trackingUrl!.length > 1
                                ? 'block'
                                : 'initial',
                          }}
                        >
                          <Group spacing={5} style={{ display: 'inline-flex' }}>
                            Vai al sito{' '}
                            {orderData!.trackingUrl!.length > 1 ? i : ''}
                            <ExternalLink size={20} />
                          </Group>
                        </Anchor>
                      ))
                    : 'Non disponibile'}
                </LoadingText>
              </>
            )}
            <LoadingText loading={orderData === undefined} mb="xs">
              <strong>Tipo di pagamento: </strong>
              {orderData?.paymentType === 'onlineOnConfirm' ? (
                'Pagamento online'
              ) : orderData?.paymentType === 'bankTransferOnConfirm' ? (
                <Group style={{ display: 'inline-flex' }} spacing="xs">
                  Bonifico bancario
                  <Popover
                    width={400}
                    position="bottom-end"
                    withArrow
                    shadow="md"
                  >
                    <Popover.Target>
                      <ActionIcon>
                        <InfoCircle />
                      </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Text size="sm">
                        IBAN: <strong>IT28S0880760790000000033111</strong>
                        <br />
                        Per maggiori informazioni su come effettuare i bonifici
                        consulta i{' '}
                        <a
                          href="https://www.tuoteam.com/termini"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Termini e condizioni
                        </a>
                      </Text>
                    </Popover.Dropdown>
                  </Popover>
                </Group>
              ) : (
                <Group style={{ display: 'inline-flex' }} spacing="xs">
                  Bonifico bancario
                  <Popover
                    width={400}
                    position="bottom-end"
                    withArrow
                    shadow="md"
                  >
                    <Popover.Target>
                      <ActionIcon>
                        <InfoCircle />
                      </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Text size="sm">
                        IBAN: <strong>IT28S0880760790000000033111</strong>
                        <br />
                        Per maggiori informazioni su come effettuare i bonifici
                        consulta i{' '}
                        <a
                          href="https://www.tuoteam.com/termini"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Termini e condizioni
                        </a>
                      </Text>
                    </Popover.Dropdown>
                  </Popover>
                </Group>
              )}
            </LoadingText>

            <LoadingText loading={orderData === undefined || isProductsLoading}>
              <strong>Totale (Iva In.): </strong>€{' '}
              {orderData?.status === 'inserted' ||
              orderData?.status === 'waitingConfirmation' ||
              orderData?.status === 'waitingPayment' ? (
                <>
                  {totalPriceNoDiscount.toFixed(2)}{' '}
                  {orderData?.paymentType !== 'onlineOnConfirm' && (
                    <>(€ {totalPrice.toFixed(2)} pagando online)</>
                  )}
                </>
              ) : orderData?.total !== totalPriceNoDiscount ? (
                <>
                  <span style={{ textDecoration: 'line-through' }}>
                    {totalPriceNoDiscount.toFixed(2)}
                  </span>{' '}
                  {orderData?.paymentType !== 'onlineOnConfirm' && (
                    <>{orderData?.total?.toFixed(2)}</>
                  )}
                </>
              ) : (
                <>{totalPriceNoDiscount.toFixed(2)}</>
              )}{' '}
            </LoadingText>

            {orderData?.status === 'waitingPayment' &&
              (orderData?.paymentType === 'bankTransferOnConfirm' ? (
                <p style={{ maxWidth: '300px' }}>
                  Puoi scegliere di pagare subito il tuo ordine tramite carta di
                  credito o paypal ed ottenere uno sconto del 3%, oppure
                  proseguire con il pagamento tramite bonifico bancario.
                </p>
              ) : (
                orderData?.paymentType === 'other' && (
                  <p style={{ maxWidth: '300px' }}>
                    Puoi scegliere di pagare subito il tuo ordine tramite carta
                    di credito o paypal ed ottenere uno sconto del 3%, oppure
                    proseguire con il pagamento tramite bonifico bancario.
                  </p>
                )
              ))}

            {orderData?.status === 'waitingPayment' && (
              <Stack align="start" mt="md">
                <Button
                  leftIcon={<CreditCard />}
                  onClick={handlePayClick}
                  loading={isLoadingStripe || isLoadingConfirmPaymentType}
                >
                  Paga con carta di credito
                </Button>
                <PayPalButtons
                  createOrder={handlePaypalCreateOrder}
                  onApprove={handlePaypalApprove}
                  disabled={isLoadingConfirmPaymentType}
                />
                {orderData?.paymentType === 'other' && (
                  <Button
                    variant="default"
                    onClick={handleConfirmBankTransferClick}
                    loading={isLoadingStripe || isLoadingConfirmPaymentType}
                  >
                    Prosegui con bonifico bancario
                  </Button>
                )}
              </Stack>
            )}
          </Card>
        </Group>
        <SearchBar
          placeholder="Ricerca per codice o nome prodotto"
          value={filters.searchQuery}
          onChange={(newValue) =>
            setFilters({ ...filters, searchQuery: newValue })
          }
        />
        <SortableTable
          data={products}
          headings={headings}
          sortableKeys={['productCode', 'name']}
          onSortingChange={(key: string, order: SortOrder) =>
            setFilters({ ...filters, sortBy: key as SortBy, sortOrder: order })
          }
          emptyText="Nessun prodotto trovato"
          loading={isProductsLoading || isLoadingProductsCount}
        />
        {products.length > 0 && (
          <PaginationRow
            page={filters.page!}
            pageLength={filters.pageLength!}
            totalPages={totalPages}
            onPageChange={(newPage) =>
              setFilters({ ...filters, page: newPage })
            }
            onPageLengthChange={(newPageLength) =>
              setFilters({ ...filters, pageLength: newPageLength, page: 1 })
            }
          />
        )}
      </Layout>
    </PayPalScriptProvider>
  );
};

// Destinazione merce ha ragione sociale

export default OrderDetail;
