import { createApi } from '@reduxjs/toolkit/query/react';

import { SortOrder } from '@domain/types';

import { baseQuery } from './';

interface Invoice {
  id: string;
  date: string;
  pdfName: string;
  userId: number;
}

interface InvoicesCount {
  count: number;
}

export type SortBy = 'id' | 'date';

export interface InvoiceApiQueryParams {
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  page?: number;
  pageLength?: number;
}

const api = createApi({
  baseQuery: baseQuery,
  reducerPath: 'api/invoices',
  endpoints: (builder) => ({
    getInvoices: builder.query<Invoice[], InvoiceApiQueryParams>({
      query: (params) => ({
        url: 'invoices',
        params,
      }),
    }),
    getInvoicesCount: builder.query<InvoicesCount, void>({
      query: () => ({
        url: 'invoices/count',
      }),
    }),
  }),
});

export const { useGetInvoicesQuery, useGetInvoicesCountQuery } = api;
export default api;
