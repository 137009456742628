import { FC } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Check } from 'tabler-icons-react';

import {
  Anchor,
  Button,
  Group,
  PasswordInput,
  SegmentedControl,
  Select,
  Text,
  TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import env from '@config/env';

import { useGetCittaQuery, useGetProvinceQuery } from '@api/locationsApi';
import { ProfileType, useRegisterUserMutation } from '@api/userApi';

import useAuth from '@hooks/useAuth';

import FormsPaper from '@components/FormsPaper';
import GuestLayout from '@components/layout/guestLayout/GuestLayout';

import useStyles from './register.style';

const Register: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { classes } = useStyles();

  const { user } = useAuth();

  const navigate = useNavigate();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: '',
    emailConfirm: '',
    password: '',
    passwordConfirm: '',
    name: '',
    surname: '',
    companyName: '',
    vatTaxNumber: '',
    phone: '',
    pec: '',
    sdi: '',
    address: '',
    city: '',
    zip: '',
    province: '',
    type: 'private',
  };

  const form = useForm({
    initialValues,
    validate: {
      emailConfirm: (value, values) =>
        value !== values.email ? 'Le email non corrispondono.' : null,
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Le password non corrispondono.' : null,
    },
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      if (env.ENV === 'production') {
        // @ts-ignore
        window['grecaptcha'].ready(function () {
          // @ts-ignore
          window['grecaptcha']
            .execute(env.RECAPTCHA_PUBLIC_KEY, { action: 'submit' })
            .then(async (token: string) => {
              await register({
                ...values,
                recaptchaToken: token,
                type: values.type as ProfileType,
              }).unwrap();
            });
        });
      } else {
        await register({
          ...values,
          type: values.type as ProfileType,
        }).unwrap();
      }

      showNotification({
        title: 'Benvenuto nel team!',
        message:
          'La registrazione è andata a buon fine. Ora puoi accedere alla tua area riservata.',
        icon: <Check />,
        color: 'teal',
      });

      navigate('/');
    } catch (e: any) {
      if (e.status === 400) {
        const mappedErrors: any = {};

        for (const [key, value] of Object.entries(e.data.errors)) {
          mappedErrors[key] = (value as string[]).join(' - ');
        }

        form.setErrors({
          general: e.data.message,
          ...mappedErrors,
        });
      } else {
        form.setErrors({
          general: 'Errore inatteso. Per favore riprova più tardi.',
        });
      }
    }
  };

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: province, isLoading: isLoadingProvince } =
    useGetProvinceQuery();

  const { data: citta, isLoading: isLoadingCitta } = useGetCittaQuery(
    form.values.province,
    { skip: form.values.province === '' }
  );

  const [register, { isLoading }] = useRegisterUserMutation();

  // ==========================================================================
  // Render
  // ==========================================================================
  return user ? (
    <Navigate to="/ordini" replace={true} />
  ) : (
    <GuestLayout
      size={600}
      titleComponent={
        <>
          Hai già un account?{' '}
          <Anchor to="/" component={Link} size="sm">
            Accedi
          </Anchor>
        </>
      }
    >
      <FormsPaper mt={30}>
        <form
          onSubmit={form.onSubmit((values) => {
            onSubmit(values);
          })}
        >
          <Group noWrap className={classes.inputGroup}>
            <TextInput
              className={classes.inputField}
              label="Email"
              placeholder="email@example.com"
              required
              {...form.getInputProps('email')}
            />
            <TextInput
              className={classes.inputField}
              label="Ripeti email"
              placeholder="email@example.com"
              required
              {...form.getInputProps('emailConfirm')}
            />
          </Group>
          <Group noWrap mt="md" className={classes.inputGroup}>
            <PasswordInput
              className={classes.inputField}
              label="Password"
              placeholder="La tua password"
              required
              {...form.getInputProps('password')}
            />
            <PasswordInput
              className={classes.inputField}
              label="Ripeti password"
              placeholder="La tua password"
              required
              {...form.getInputProps('passwordConfirm')}
            />
          </Group>
          <Group mt="md">
            <SegmentedControl
              color="brand"
              value={form.values.type}
              onChange={(value) => form.setFieldValue('type', value)}
              data={[
                { label: 'Privato', value: 'private' },
                { label: 'Azienda', value: 'company' },
              ]}
            />
          </Group>
          {form.values.type === 'company' && (
            <>
              <Group noWrap mt="md" className={classes.inputGroup}>
                <TextInput
                  className={classes.inputField}
                  label="Ragione sociale"
                  placeholder="Azienda s.r.l."
                  required
                  {...form.getInputProps('companyName')}
                />
                <TextInput
                  className={classes.inputField}
                  label="P. Iva o Codice fiscale"
                  placeholder="PIVA o CF"
                  required
                  {...form.getInputProps('vatTaxNumber')}
                />
              </Group>
              <Group noWrap mt="md" className={classes.inputGroup}>
                <TextInput
                  className={classes.inputField}
                  label="SDI"
                  placeholder="SDI dell'azienda"
                  required
                  {...form.getInputProps('sdi')}
                />
                <TextInput
                  className={classes.inputField}
                  label="Pec"
                  placeholder="aziendasrl@pec.it"
                  {...form.getInputProps('pec')}
                />
              </Group>
            </>
          )}
          {form.values.type === 'private' && (
            <>
              <Group noWrap mt="md" className={classes.inputGroup}>
                <TextInput
                  className={classes.inputField}
                  label="Nome"
                  placeholder="Il tuo nome"
                  required
                  {...form.getInputProps('name')}
                />
                <TextInput
                  className={classes.inputField}
                  label="Cognome"
                  placeholder="Il tuo cognome"
                  required
                  {...form.getInputProps('surname')}
                />
              </Group>
              <Group noWrap mt="md" className={classes.inputGroup}>
                <TextInput
                  className={classes.inputField}
                  label="Codice fiscale"
                  placeholder="Il tuo codice fiscale"
                  required
                  {...form.getInputProps('vatTaxNumber')}
                />
              </Group>
            </>
          )}
          <Group noWrap mt="md" className={classes.inputGroup}>
            <Select
              className={classes.inputField}
              label="Provincia"
              placeholder="Seleziona provincia"
              searchable
              nothingFound="Nessun risultato"
              required
              disabled={isLoadingProvince}
              data={
                province
                  ? province.map((provincia) => ({
                      value: provincia.sigla,
                      label: provincia.nome,
                    }))
                  : []
              }
              {...form.getInputProps('province')}
              onChange={(e) => {
                form.setFieldValue('city', '');
                form.setFieldValue('zip', '');
                form.getInputProps('province').onChange(e);
              }}
            />
          </Group>
          <Group noWrap mt="md" className={classes.inputGroup}>
            <Select
              className={classes.inputField}
              label="Città"
              placeholder="Seleziona città"
              searchable
              nothingFound="Nessun risultato"
              required
              disabled={form.values.province === '' || isLoadingCitta}
              data={
                citta
                  ? citta.map((citta) => ({
                      value: citta.nome,
                      label: citta.nome,
                    }))
                  : []
              }
              {...form.getInputProps('city')}
              onChange={(e) => {
                form.setFieldValue('zip', '');
                form.getInputProps('city').onChange(e);
              }}
            />
            <Select
              className={classes.inputField}
              label="CAP"
              placeholder="Seleziona CAP"
              searchable
              nothingFound="Nessun risultato"
              required
              disabled={form.values.city === '' || isLoadingCitta}
              data={
                citta && form.values.city
                  ? citta.filter((citta) => citta.nome === form.values.city)[0]
                      .cap
                  : []
              }
              {...form.getInputProps('zip')}
            />
          </Group>
          <Group noWrap mt="md" className={classes.inputGroup}>
            <TextInput
              className={classes.inputField}
              label="Indirizzo"
              placeholder="Via Principale, 20"
              required
              {...form.getInputProps('address')}
            />
            <TextInput
              className={classes.inputField}
              label="Telefono"
              placeholder="345 1213456"
              required
              {...form.getInputProps('phone')}
            />
          </Group>
          {form.errors.general && (
            <Text color="red" size="sm" mt="xl">
              {form.errors.general}
            </Text>
          )}
          <Button
            type="submit"
            mx="auto"
            mt="xl"
            style={{ display: 'block' }}
            loading={isLoading}
          >
            Registrati
          </Button>
        </form>
      </FormsPaper>
    </GuestLayout>
  );
};

export default Register;
