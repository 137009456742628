export const ORDER_STATUS_COLORS = {
  inserted: 'grape',
  waitingConfirmation: 'orange',
  waitingPayment: 'orange',
  confirmed: 'blue',
  production: 'yellow',
  closed: 'green',
};

export const ORDER_STATUS_STRINGS = {
  inserted: 'In elaborazione',
  waitingConfirmation: 'Attesa di conferma',
  waitingPayment: 'Attesa di pagamento',
  confirmed: 'Confermato',
  production: 'In produzione',
  closed: 'Spedito',
};
