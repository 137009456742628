import { FC, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CircleCheck, FileText } from 'tabler-icons-react';

import { Button, Group, Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import env from '@config/env';

import { SortOrder } from '@domain/types';

import {
  OfferProductApiQueryParams,
  useConfirmOfferMutation,
  useGetOfferProductsCountQuery,
  useGetOfferProductsQuery,
  useGetSingleOfferQuery
} from '@api/offersApi';
import { SortBy } from '@api/ordersApi';

import { NotFound } from '@pages';

import Card from '@components/card/Card';
import Layout from '@components/layout/Layout';
import LoadingText from '@components/LoadingText';
import PaginationRow from '@components/PaginationRow';
import SearchBar from '@components/SearchBar';
import SortableTable from '@components/sortableTable/SortableTable';

const OfferDetail: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { id } = useParams();

  const [searchParams] = useSearchParams();

  // ==========================================================================
  // State
  // ==========================================================================
  const [filters, setFilters] = useState<OfferProductApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 5),
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: offerData, isLoading, error } = useGetSingleOfferQuery(id!);

  const [confirmOffer, { isLoading: isLoadingConfirmOffer }] =
    useConfirmOfferMutation();

  // Get initial products count
  const {
    data: productsCount = { count: 0 },
    isLoading: isLoadingProductsCount,
  } = useGetOfferProductsCountQuery({ id: id!, searchQuery });

  const { data: productsData = [], isLoading: isProductsLoading } =
    useGetOfferProductsQuery({
      id: id!,
      params: { ...filters, searchQuery },
    });

  // Map data
  const products = productsData.map((product) => {
    const data = [
      <img
        src={product.imageUrl}
        alt={product.name}
        style={{ height: '2rem' }}
      />,
      product.productCode,
      product.name,
      product.color,
      product.size,
      product.quantity,

      `€ ${product.unitPrice.toFixed(2)}`,
      <Group spacing={3} noWrap={true}>
        <span>€</span>
        {product.salePercent && (
          <span>
            <span style={{ textDecoration: 'line-through' }}>
              {product.unitPrice * product.quantity}
            </span>
          </span>
        )}
        <span>
          {(
            product.unitPrice *
            product.quantity *
            (1 - (product.salePercent || 0) / 100)
          ).toFixed(2)}
        </span>
      </Group>,
      product.salePercent ? `${product.salePercent}%` : '-',
    ];

    return {
      key: product.id,
      data,
    };
  });

  // ==========================================================================
  // Handler
  // ==========================================================================
  const handleConfirmClick = async () => {
    try {
      await confirmOffer({ id: id! }).unwrap();
    } catch (e) {
      console.error(e);

      showNotification({
        title: "Errore nella conferma dell'offerta!",
        message: "Impossibile confermare l'offerta.",
        color: 'red',
      });
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const headings = {
    imageUrl: '',
    productCode: 'Codice',
    name: 'Nome',
    color: 'Colore',
    size: 'Taglia',
    quantity: 'Quantità',
    unitPrice: 'Prezzo unitario',
    totalPrice: 'Prezzo totale',
    salePercent: 'Sconto',
  };

  const totalPages = Math.ceil(productsCount.count / filters.pageLength!);

  return error && (error as FetchBaseQueryError).status === 404 ? (
    <NotFound />
  ) : (
    <Layout
      title={`Dettagli offerta #${id}`}
      backLink={{ title: 'Lista offerte', to: '/offerte' }}
      loading={isLoading || isLoadingProductsCount}
    >
      <Group spacing="xl" align="flex-start" position="center" grow mb={70}>
        <Card
          title="Informazioni offerta"
          action={{
            title: 'Scarica pdf',
            icon: <FileText />,
            onClick: () => {
              window.open(
                `${env.RESOURCES_URL}/offerte/${offerData?.pdfName}`,
                '_blank'
              );
            },
          }}
        >
          <LoadingText loading={offerData === undefined} mb="xs">
            <strong>Numero offerta: </strong>
            {offerData?.id}
          </LoadingText>
          <LoadingText loading={offerData === undefined}>
            <strong>Data: </strong>
            {new Date(offerData?.date || 0).toLocaleDateString('IT-it')}
          </LoadingText>
          {offerData?.confirmationDate ? (
            <Text mt="sm">
              <strong>Offerta confermata</strong>
            </Text>
          ) : (
            <Button
              leftIcon={<CircleCheck />}
              mt="lg"
              onClick={handleConfirmClick}
              loading={isLoadingConfirmOffer}
            >
              Conferma offerta
            </Button>
          )}
        </Card>

        <Card title="Pagamento">
          <LoadingText loading={offerData === undefined}>
            <strong>Tipo di pagamento: </strong>
            {offerData?.paymentType === 'onlineOnConfirm'
              ? 'Pagamento online'
              : offerData?.paymentType === 'bankTransferOnConfirm'
              ? 'Bonifico bancario'
              : 'Bonifico bancario'}
          </LoadingText>
        </Card>
      </Group>

      <SearchBar
        placeholder="Ricerca per codice o nome prodotto"
        value={filters.searchQuery}
        onChange={(newValue) =>
          setFilters({ ...filters, searchQuery: newValue })
        }
      />

      <SortableTable
        data={products}
        headings={headings}
        sortableKeys={['productCode', 'name']}
        onSortingChange={(key: string, order: SortOrder) =>
          setFilters({ ...filters, sortBy: key as SortBy, sortOrder: order })
        }
        emptyText="Nessun prodotto trovato"
        loading={isProductsLoading || isLoadingProductsCount}
      />
      {products.length > 0 && (
        <PaginationRow
          page={filters.page!}
          pageLength={filters.pageLength!}
          totalPages={totalPages}
          onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
          onPageLengthChange={(newPageLength) =>
            setFilters({ ...filters, pageLength: newPageLength, page: 1 })
          }
        />
      )}
    </Layout>
  );
};

export default OfferDetail;
