import { createStyles } from '@mantine/core';

const useStyles = createStyles({
  informationCard: {
    width: '100%',
    minWidth: 'max-content',
  },
});

export default useStyles;
