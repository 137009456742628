import { createStyles } from '@mantine/core';

const useStyles = createStyles({
  container: {
    position: 'relative',
  },

  icon: {
    height: '60%',
    width: '60%',
    position: 'absolute',
    bottom: '15%',
    left: '15%',
  },

  badge: {
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

export default useStyles;
