import { configureStore } from '@reduxjs/toolkit';

import authApi from '@api/authApi';
import ddtApi from '@api/ddtApi';
import graphicsDraftsApi from '@api/graphicsDraftsApi';
import invoicesApi from '@api/invoicesApi';
import locationsApi from '@api/locationsApi';
import notificationsApi from '@api/notificationsApi';
import offersApi from '@api/offersApi';
import ordersApi from '@api/ordersApi';
import passwordResetApi from '@api/passwordResetApi';
import profileSettingsApi from '@api/profileSettingsApi';
import sellersApi from '@api/sellersApi';
import userApi from '@api/userApi';

import authReducer from '@slices/authSlice';

const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [profileSettingsApi.reducerPath]: profileSettingsApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [sellersApi.reducerPath]: sellersApi.reducer,
    [locationsApi.reducerPath]: locationsApi.reducer,
    [passwordResetApi.reducerPath]: passwordResetApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [invoicesApi.reducerPath]: invoicesApi.reducer,
    [ddtApi.reducerPath]: ddtApi.reducer,
    [offersApi.reducerPath]: offersApi.reducer,
    [graphicsDraftsApi.reducerPath]: graphicsDraftsApi.reducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      userApi.middleware,
      profileSettingsApi.middleware,
      ordersApi.middleware,
      sellersApi.middleware,
      locationsApi.middleware,
      passwordResetApi.middleware,
      notificationsApi.middleware,
      invoicesApi.middleware,
      ddtApi.middleware,
      offersApi.middleware,
      graphicsDraftsApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
