import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from '@hooks/useAuth';

const PrivateRoute: FC<{ element: React.ReactElement }> = ({ element }) => {
  // ==========================================================================
  // General
  // ==========================================================================
  const location = useLocation();

  const { user } = useAuth();

  // ==========================================================================
  // Render
  // ==========================================================================
  return user ? element : <Navigate to={`/?r=${location.pathname}`} />;
};

export default PrivateRoute;
