import { FC } from 'react';

import { Anchor, Group, Text } from '@mantine/core';

interface FooterProps {
  innerRef?: React.LegacyRef<HTMLElement>;
}

const Footer: FC<FooterProps> = ({ innerRef }) => {
  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <footer ref={innerRef}>
      <Group px="md" py="sm" position="apart">
        <Group>
          <div
            dangerouslySetInnerHTML={{
              __html:
                '<a href="https://www.iubenda.com/privacy-policy/44070408" class="iubenda-black no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>',
            }}
          ></div>
          <div
            dangerouslySetInnerHTML={{
              __html:
                '<a href="https://www.iubenda.com/privacy-policy/44070408/cookie-policy" class="iubenda-black no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>',
            }}
          ></div>
        </Group>
        <Text size="xs">
          Developed by{' '}
          <Anchor
            size="xs"
            href="https://www.miroirstudio.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            MiroirStudio
          </Anchor>
        </Text>
      </Group>
    </footer>
  );
};

export default Footer;
