import { FC } from 'react';

import { Group, Text, Title, UnstyledButton } from '@mantine/core';
import { dateToDateString, dateToHourString } from '@utils/date';

import useStyles from './notificationCard.style';

interface NotificationCardProps {
  title: string;
  description: string;
  read: boolean;
  date: Date;
  image: string;
  onClick: () => void;
}

const NotificationCard: FC<NotificationCardProps> = ({
  title,
  description,
  read,
  date,
  image,
  onClick,
}) => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { classes } = useStyles();

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <UnstyledButton
      className={classes.notificationButton}
      p="md"
      onClick={onClick}
      style={{ opacity: read ? 0.5 : 1 }}
    >
      <Group position="apart">
        <Group>
          <div className={classes.notificationIcon}>
            <img src={image} alt="Icon" />
          </div>
          <div>
            <Title order={5} style={{ fontWeight: read ? 'normal' : '' }}>
              {title}
            </Title>
            <Text size="sm">{description}</Text>
          </div>
        </Group>
        <div>
          <Text size="xs" align="right">
            {dateToDateString(date)}
          </Text>
          <Text size="xs" align="right">
            {dateToHourString(date)}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  );
};

export default NotificationCard;
