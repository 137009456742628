import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './';

interface PasswordResetRequestRequest {
  email: string;
  recaptchaToken?: string;
}

interface PasswordResetRequestResponse {
  message: string;
}

interface PasswordResetRequest {
  token: string;
  body: { password: string; recaptchaToken?: string };
}

interface PasswordResetResponse {
  message: string;
}

interface CheckTokenResponse {
  message: string;
}

const api = createApi({
  baseQuery: baseQuery,
  reducerPath: 'api/passwordReset',
  endpoints: (builder) => ({
    passwordResetRequest: builder.mutation<
      PasswordResetRequestResponse,
      PasswordResetRequestRequest
    >({
      query: (body) => ({
        url: 'password-reset/request',
        method: 'POST',
        body,
      }),
    }),
    checkToken: builder.query<CheckTokenResponse, string>({
      query: (token) => ({
        url: `password-reset/${token}`,
      }),
    }),
    passwordReset: builder.mutation<
      PasswordResetResponse,
      PasswordResetRequest
    >({
      query: ({ token, body }) => ({
        url: `password-reset/${token}`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  usePasswordResetRequestMutation,
  usePasswordResetMutation,
  useCheckTokenQuery,
} = api;
export default api;
