export const firebaseConfig = {
  apiKey: 'AIzaSyAwSQIMv3o6o4bTzKhzBOmUuRV2NaMMssw',
  authDomain: 'tuoteam-280a9.firebaseapp.com',
  projectId: 'tuoteam-280a9',
  storageBucket: 'tuoteam-280a9.appspot.com',
  messagingSenderId: '785608196184',
  appId: '1:785608196184:web:07d9af5d866fc121780a2d',
};

export const webPushKey =
  'BPhWQDcYwfpsnVKkuMN1rj5V2rA6pZ64gNdDTGR7rBeoc4hBzy5ow_OY6pqSR9lzcU-GXu5tA0VkhjczsX61Rno';
