import { EmblaCarouselType } from 'embla-carousel-react';
import { FC, useEffect, useState } from 'react';
import { Check, CircleCheck } from 'tabler-icons-react';

import { Carousel, useAnimationOffsetEffect } from '@mantine/carousel';
import { Button, Center, Group, Loader, Stack, Text, Textarea, Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';

import env from '@config/env';

import {
  useConfirmGraphicMutation,
  useGetGraphicsDraftsQuery,
  useModifyGraphicMutation
} from '@api/graphicsDraftsApi';

import useStyles from './graphicsDraftsReview.style';

interface GraphicsDraftsReviewProps {
  productName?: string;
  draftProductsRelationId?: string;
  closeModal: () => void;
  transitionDuration: number;
}

const GraphicsDraftsReview: FC<GraphicsDraftsReviewProps> = ({
  productName,
  draftProductsRelationId,
  closeModal,
  transitionDuration,
}) => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { classes } = useStyles();

  // ==========================================================================
  // State
  // ==========================================================================
  const [embla, setEmbla] = useState<EmblaCarouselType | null>(null);

  useAnimationOffsetEffect(embla!, transitionDuration);

  const [notes, setNotes] = useState<{ [x: string]: string }>({});

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data, isLoading } = useGetGraphicsDraftsQuery(
    draftProductsRelationId!,
    { skip: draftProductsRelationId === undefined }
  );

  const [modifyGraphic, { isLoading: isModifyGraphicLoading }] =
    useModifyGraphicMutation();

  const [confirmGraphic, { isLoading: isConfirmGraphicLoading }] =
    useConfirmGraphicMutation();

  useEffect(() => {
    if (data) {
      const initialNotes: { [x: string]: string } = {};

      data.forEach((graphicDraft) => {
        if (graphicDraft.note) {
          initialNotes[graphicDraft.id] = graphicDraft.note;
        }
      });

      setNotes(initialNotes);
    }
  }, [data]);

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleSaveNoteGraphicClick = async (id: string) => {
    try {
      await modifyGraphic({ id, body: { note: notes[id] } }).unwrap();

      showNotification({
        title: 'Nota salvata',
        message: 'Nota salvata con successo.',
      });
    } catch (e: any) {
      if (e.status === 400) {
        showNotification({
          title: 'Errore nel salvataggio della nota!',
          message: e.data.errors.note,
          color: 'red',
        });
      } else {
        showNotification({
          title: 'Errore nel salvataggio della nota!',
          message: 'Impossibile salvare la nota. Riprova più tardi.',
          color: 'red',
        });
      }
    }
  };

  const handleConfirmGraphicClick = async (id: string) => {
    try {
      await confirmGraphic(id).unwrap();

      showNotification({
        title: 'Grafica confermata',
        message: 'Grafica confermata con successo.',
        icon: <Check />,
        color: 'teal',
      });
    } catch (e) {
      showNotification({
        title: 'Errore nella conferma della grafica!',
        message: 'Impossibile procedere con la conferma. Riprova più tardi.',
        color: 'red',
      });
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <div className={classes.modalContainer}>
      <Title order={5}>{productName}</Title>
      {isLoading ? (
        <Center my="xl">
          <Loader />
        </Center>
      ) : data?.length === 0 ? (
        <Text my="xl">Nessuna bozza per il prodotto selezionato</Text>
      ) : (
        <Carousel
          withControls
          withIndicators
          height="85vh"
          styles={{
            control: {
              backgroundColor: '#888888',
              borderColor: '#777777',
              color: '#ffffff',

              '&[data-inactive]': {
                opacity: 0,
                cursor: 'default',
              },
            },
            indicator: {
              backgroundColor: '#888888',
            },
          }}
          slideSize="100%"
          align="start"
          mt="md"
          getEmblaApi={setEmbla}
        >
          {data!.map((graphicsDraft) => {
            return (
              <Carousel.Slide key={graphicsDraft.id}>
                <div className={classes.slideContainer}>
                  <img
                    className={classes.slideImage}
                    src={`${env.RESOURCES_URL}/bozzeGrafiche/${graphicsDraft.graphicUrl}`}
                    alt={productName}
                  />
                  <Stack px="lg" mt="md" mb={40}>
                    <Textarea
                      maxLength={450}
                      placeholder="Inserisci qui le tue note"
                      value={notes[graphicsDraft.id]}
                      onChange={(e) =>
                        setNotes({
                          ...notes,
                          [graphicsDraft.id]: e.target.value,
                        })
                      }
                    />
                    <Group position="apart">
                      <Button
                        variant="outline"
                        disabled={
                          !notes[graphicsDraft.id] ||
                          notes[graphicsDraft.id].trim() === '' ||
                          notes[graphicsDraft.id] === graphicsDraft.note
                        }
                        onClick={() =>
                          handleSaveNoteGraphicClick(graphicsDraft.id)
                        }
                        loading={isModifyGraphicLoading}
                      >
                        Salva nota
                      </Button>
                      {graphicsDraft.confirmationDate ? (
                        <Text color="green" style={{ display: 'flex' }}>
                          <CircleCheck /> Grafica confermata
                        </Text>
                      ) : (
                        <Button
                          loading={isConfirmGraphicLoading}
                          onClick={() =>
                            handleConfirmGraphicClick(graphicsDraft.id)
                          }
                        >
                          Conferma grafica
                        </Button>
                      )}
                    </Group>
                  </Stack>
                </div>
              </Carousel.Slide>
            );
          })}
        </Carousel>
      )}
    </div>
  );
};

export default GraphicsDraftsReview;
