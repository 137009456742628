import { FC } from 'react';
import { Link } from 'react-router-dom';

import { ActionIcon, Badge } from '@mantine/core';

import notificationIcon from '@images/icons/notification-icon.svg';

import useStyles from './iconBadge.style';

interface IconBadgeProps {
  badgeText?: string | null;
  to: string;
}

const IconBadge: FC<IconBadgeProps> = ({ badgeText, to }) => {
  // ==========================================================================
  // General
  // ==========================================================================
  const { classes } = useStyles();

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <ActionIcon
      to={to}
      component={Link}
      className={classes.container}
      size="xl"
    >
      <div
        className={classes.icon}
        style={{ bottom: badgeText ? '15%' : '20%' }}
      >
        <img
          src={notificationIcon}
          alt="Icona notifica"
          style={{ height: '100%', width: '100%' }}
        />
      </div>
      {badgeText && (
        <Badge className={classes.badge} variant="filled" size="sm">
          {badgeText}
        </Badge>
      )}
    </ActionIcon>
  );
};

export default IconBadge;
