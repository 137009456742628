const env = {
  ENV: process.env.REACT_APP_ENV || 'development',
  API_URL:
    process.env.REACT_APP_API_URL !== ''
      ? process.env.REACT_APP_API_URL
      : `${window.location.protocol}//${window.location.hostname}:5000/api`,
  API_LOGIN_PATH: process.env.REACT_API_LOGIN_PATH || 'auth/login',
  API_REFRESH_PATH: process.env.REACT_API_REFRESH_PATH || 'auth/refresh',
  RECAPTCHA_PUBLIC_KEY: process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY,
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY || '',
  PAYPAL_CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID || '',
  RESOURCES_URL: process.env.REACT_APP_RESOURCES_URL || '',
};

export default env;
